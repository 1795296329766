import React, { Component } from "react";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

import neighborhoods from "../assets/json/neighborhoods.json";
//import neighborhoods_new from '../assets/json/neighborhoods_new.json'
import subwayLines from "../assets/json/subway_lines.json";

//import neighbors from '../assets/json/neighbors.json'

import { Segment, Sidebar, Label, Button, Grid, Loader, Dimmer, Divider } from "semantic-ui-react";

import SearchPanel from "./SearchPanel";
import HorizontalSidebar, { FullLotInformation } from "./HorizontalSidebar";

import { subway_colors, subway_symbols } from "../utility/SubwayConstants";
import {
  mapbox_key,
  property_api_external_information_url,
  property_api_add_to_watchlist_url,
  property_api_add_to_ownership_url,
  property_api_add_to_contract_url,
  property_api_update_lot_notes_url,
  property_api_update_wide_street_url,
  property_api_get_neighbors_url,
  property_api_get_empty_streets_url,
  property_api_get_lot_url,
  property_api_update_unitsres_url,
  property_api_update_bldgarea_url,
  property_api_run_prospects_url,
  property_api_update_proxcode_url,
  property_api_update_construction_url,
  property_api_get_document_connections_url,
  property_api_zestimate_url,
  parcel_get_url,
  property_api_get_ai_narrative_url,
  property_api_urbyn_search_address_url,
  property_api_run_lender_summary_url,
  property_api_run_lender_txs_url,
  property_api_run_borrower_txs_url,
  property_api_run_borrowers_by_address_url,
} from "../utility/Config";
import {
  resetUnitFilter,
  cleanZeroes,
  MapMode,
  LenderProspectingMode,
  mute_basemap,
  CompBucketOption,
  CompTypeOption,
  isDeedType,
  searchFilters,
  Settings,
  today,
  EXTENDED_COMP_BUCKETS,
  DataSetType,
} from "../utility/Global";
import {
  makeGetRequestWithCallback,
  makePutRequest,
  makeGetRequest,
  makePostRequest,
  getCityData,
} from "../utility/Services";
import { ProspectingTable, CompsTableV3, LenderSummaryTable, LenderTransactionsTable, BorrowerTransactionsTable, UniqueBorrowerTable } from "./Tables";
import {
  runComps,
  runCityComps,
  removeComps,
  changeMarkers,
  runCompMarkers,
  removeCompMarkers,
  bucketColor,
  runHistoricalComps,
  csvColumns,
  runHistoricalCompPrice,
  runCombinedComps,
} from "./Comps";
import { CompsFilter, ProspectingFilterPanel } from "./CompsFilter";
import Lot from "../Tax-Lot.js";

import {
  onlyUnique,
  property,
  flatten,
  isEmpty,
  roundToNearest,
  getMedian,
  getParcelAddressQueryParams,
  downloadCSV,
} from "../utility/Helper";
import {
  removeSourceFromMap,
  createGeoJSONCircle,
  addCircleToMap,
  addLineToMap,
  createGeoJSONLine,
  LineDirection,
} from "../utility/MapHelper";
import { CompsPopup } from "./CompsPopup";
import { CompsSidebar, CompsExcludedSidebar, CombinedCompsSidebar } from "./CompsSidebar";
import { getChartMovingAverage } from "./charts/ChartHelper";
import { useParams } from "react-router-dom";
import CompsCalcPanel, {  
  CompsClusterBucketsSummaryPanel,
} from "./CompsCalcPanel";
import { ZillowEstimatesPanel } from "./lots/LotInformationPanel";
import LTDSChart, { getTDSChartData } from "./charts/LTDSChart";
import HistoryChart from "./charts/HistoryChart";
import ArrowTriangle from "./extras/ArrowTriangle.js";

mapboxgl.accessToken = mapbox_key;

const cachedProperties = {};
const cachedBBLs = {};

let alreadyMoved = false;
let alreadyFlown = false;

let map = {};
let marker = null;
let secondaryMarker = null;

let polygonBounds = null;
let previousDrawId = null;

let compsInitialized = false;

let cachedCompsData = {};
let cachedChart = {};
let cachedLendersProspectFilter = {};

const initialSearchState = {
  isLoading: false,
  results: [],
  value: "",
  selectedLot: null,
};

const initialState = {
  initialized: false,
  lng: -73.733,
  lat: 40.7125,
  zoom: 9.72,
  visible: false,
  compsSidebarVisible: false,
  selectedLot: null,
  hoverLot: null,
  compsPrimaryLot: null,
  compsSecondaryLot: null,
  compsHoverMarker: null,
  isLoading: false,
  isLoadingLots: false,
  searchState: JSON.parse(JSON.stringify(initialSearchState)),
  compsLoading: false,
  compsData: {},
  prospectingData: [],
  lenderSummaryData: [],
  lenderTransactionsData: [],
  borrowerTransactionsData: [],
  prospectingLenderBreadcrumbs: [],
  compsOverride: false,
  compsVisible: false,
  compsBucket: CompBucketOption.HIGH,
  compType: CompTypeOption.NONE,
  rentCompBedroomMode: null,
  filterVisible: false,
  prospectingVisible: false,
  prospectingFilterVisible: false,
  mapMode: MapMode.SEARCH,
  lenderProspectingMode: LenderProspectingMode.NONE,
  containerClasses: [],
  tmpSelectedCompDetails: null,
  selectedCompDetails: null,
  compsDate: today(),
};

function addToArray(arr, item) {
  if (!arr.includes(item)) {
    arr.push(item);
  }
}

function removeFromArray(arr, item) {
  const index = arr.indexOf(item);
  if (index > -1) {
    arr.splice(index, 1);
  }
}

const AddressDisplayPanel = ({ lot }) => (
  <Segment className="address-display-panel">
    <p>{lot.address}</p>
  </Segment>
);

function getChartAvgSaleLine(lowX, highX, yValue, color) {
  let data = {
    color: color,
    data: [
      { x: lowX, y: yValue ?? 0 },
      { x: highX, y: yValue ?? 0 },
    ],
  };
  return data;
}

export const HomeWrapper = () => {
  const params = useParams();

  return <Home params={params} />;
};

export class Home extends Component {
  state = initialState;
  getNeighbors = this.getNeighbors.bind(this);
  getEmptyStreets = this.getEmptyStreets.bind(this);
  searchTimer = null;
  lastSearchedTerm = null;
  mapContainerRef = React.createRef();

  initMapWithRouting() {
    //console.log(this.props)
    if (this.props.params) {
      const { boro, block, lot, bbl, pid, rid } = this.props.params;

      if (boro && block && lot) {
        this.loadLotByBoroBlockLot(
          cleanZeroes(boro),
          cleanZeroes(block),
          cleanZeroes(lot)
        );
      } else if (bbl) {
        this.loadLotByBBL(bbl, DataSetType.PLUTO);
      } else if (pid) {
        this.loadLotByBBL(pid, DataSetType.FIRST_AMERICAN);
      } else if (rid) {
        this.loadLotByBBL(rid, DataSetType.REAL_ESTATE_API);
      }
    }
  }

  async getEmptyStreets() {
    const {selectedLot} = this.state
    if (!selectedLot || !Lot.isPluto(selectedLot)) return;
    if (map.getZoom() < 14) {
      return;
    }
    if (this.state.isLoadingEmptyStreets) {
      return;
    }
    this.setState({ isLoadingEmptyStreets: true });

    var curr_bounds = map.getBounds(),
      sw = curr_bounds.getSouthWest(),
      ne = curr_bounds.getNorthEast();

    //console.log(curr_bounds, bounds)
    // console.log(sw)
    // console.log(ne)

    const result = await makeGetRequest(
      `${property_api_get_empty_streets_url}?lnga=${sw.lng}&lata=${sw.lat}&lngb=${ne.lng}&latb=${ne.lat}`
    );
    this.setState({ isLoadingEmptyStreets: false });
    if (result) {
      const parcels = JSON.parse(result);

      if (map.getLayer("empty-streets")) {
        map.removeLayer("empty-streets");
      }
      // if (map.getLayer('empty-streets-parcel-outlines')) {
      //   map.removeLayer('empty-streets-parcel-outlines');
      // }
      if (map.getSource("empty-streets")) {
        map.removeSource("empty-streets");
      }

      map.addSource("empty-streets", { type: "geojson", data: parcels });

      map.addLayer({
        id: "empty-streets",
        type: "fill",
        source: "empty-streets",
        layout: {},
        paint: {
          "fill-color": "transparent",
          "fill-outline-color": "red",
        },
      });

      // map.addLayer({
      // "id": "empty-streets-parcel-outlines",
      // "type": "line",
      // "source": "empty-streets",
      // "layout": {},
      // "paint": {
      // "line-color": ["case",
      // ["boolean", ["feature-state", "hover"], false],
      // '#627BC1',
      // '#CCC'
      // ],
      // "line-width": ["case",
      // ["boolean", ["feature-state", "hover"], false],
      // 2,
      // 0
      // ]
      // }
      // });
    }
  }

  async getNeighbors() {
    const { mapMode, selectedLot } = this.state;
    if (mapMode === MapMode.COMPS) {
      return;
    }
    if (map.getZoom() < 15) {
      return;
    }
    if (this.state.isLoadingLots) {
      return;
    }
    this.setState({ isLoadingLots: true });

    var curr_bounds = map.getBounds(),
      sw = curr_bounds.getSouthWest(),
      ne = curr_bounds.getNorthEast();

    //console.log(curr_bounds, bounds)
    // console.log(sw)
    // console.log(ne)

    var dataset = selectedLot ? selectedLot.type : DataSetType.PLUTO

    var model = {
      longitudeA: sw.lng,
      latitudeA: sw.lat,
      longitudeB: ne.lng,
      latitudeB: ne.lat,
      dataset: dataset
    }

    const result = await makePostRequest(
      `${property_api_get_neighbors_url}`, model
    );
    this.setState({ isLoadingLots: false });
    if (result) {
      const parcels = JSON.parse(result);

      if (map.getLayer("neighbors-pluto")) {
        map.removeLayer("neighbors-pluto");
      }
      if (map.getLayer("parcel-outlines-pluto")) {
        map.removeLayer("parcel-outlines-pluto");
      }
      if (map.getSource("neighbors-pluto")) {
        map.removeSource("neighbors-pluto");
      }

      map.addSource("neighbors-pluto", { type: "geojson", data: parcels });

      map.addLayer({
        id: "neighbors-pluto",
        type: "fill",
        source: "neighbors-pluto",
        layout: {},
        paint: {
          "fill-color": "#dfe1fb",
          "fill-outline-color": "#737373",
          "fill-opacity": 0.4
        },
      });

      map.addLayer({
        id: "parcel-outlines-pluto",
        type: "line",
        source: "neighbors-pluto",
        layout: {},
        paint: {
          "line-color": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            "#627BC1",
            "#CCC",
          ],
          "line-width": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            2,
            0,
          ],
        },
      });
    }
  }

  getParcels() {
    map.addSource("parcels", { type: "vector",  tiles: ['https://tileserver.composyte.com/data/parcels/{z}/{x}/{y}.pbf'], maxzoom: 15 })

    
    map.addLayer({
      id: "parcels",
      type: "fill",
      source: "parcels",
      'source-layer': 'parcel_layer', 
      layout: {},
      paint: {
        "fill-color": "#fbfbdf",
        "fill-outline-color": "#737373",
        "fill-opacity": 0.5
      },
      minzoom: 13,
    });

    map.addLayer({
      id: "parcel-outlines",
      type: "line",
      source: "parcels",
      'source-layer': 'parcel_layer', 
      layout: {},
      paint: {
        "line-color": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          "#627BC1",
          "#CCC",
        ],
        "line-width": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          2,
          0,
        ],
      },
      minzoom: 13,
    });
    
    /*
    map.addLayer({
      id: 'parcels-stroke',
      type: 'line',
      source: 'parcels',
      'source-layer': 'parcel_layer', 
      paint: {
          'line-color': '#d4d4d5',
          'line-opacity': 0.4,
          'line-width': 1,
      },
      minzoom: 13,
      maxzoom: 19
  });*/
  
  }

  getFloodZones() {
    map.addSource("floodzones", { type: "vector",  tiles: ['https://tileserver.composyte.com/data/floodzones/{z}/{x}/{y}.pbf'], maxzoom: 14 })

    map.addLayer({
      id: 'floodzones',
      type: 'fill',
      source: 'floodzones',
      'source-layer': 'flood_layer',
      paint: {
          'fill-color': "#9356fe",
          'fill-opacity': 0.25,
      },
      minzoom: 10,
      maxzoom: 14
    });
  }

  getNeighborhoods() {
    map.addSource("neighborhoods", { type: "geojson", data: neighborhoods });

    map.addLayer({
      id: "neighborhoods",
      type: "fill",
      source: "neighborhoods",
      layout: {},
      paint: {
        "fill-color": "transparent",
        "fill-outline-color": "transparent",
      },
    });

    // map.addLayer({
    // "id": "neighborhoods-outlines",
    // "type": "line",
    // "source": "neighborhoods",
    // "layout": {},
    // "paint": {
    // "line-color": ["case",
    // ["boolean", ["feature-state", "hover"], false],
    // '#627BC1',
    // '#CCC'
    // ],
    // "line-width": ["case",
    // ["boolean", ["feature-state", "hover"], false],
    // 2,
    // 0
    // ]
    // }
    // });

    map.addLayer({
      id: "neighborhoods-outlines",
      type: "line",
      source: "neighborhoods",
      layout: {},
      paint: {
        "line-color": "#B3B3B3",
        "line-width": 2,
      },
      minzoom: 12,
    });

    // map.addSource('neighborhoods_new', { type: 'geojson', data: neighborhoods_new });

    // map.addLayer({
    //   'id': 'neighborhoods_new',
    //   'type': 'fill',
    //   'source': 'neighborhoods_new',
    //   'layout': {},
    //   'paint': {
    //   'fill-color' : 'transparent',
    //   'fill-outline-color': 'transparent'
    //   }
    // });

    //   map.addLayer({
    //     "id": "neighborhoods-new-outlines",
    //     "type": "line",
    //     "source": "neighborhoods_new",
    //     "layout": {},
    //     "paint": {
    //       "line-color": '#ff0000',
    //       "line-width": 2
    //       },
    //     "minzoom": 12
    //   });

    //       let src = 'neighbors-hm'
    //   map.addSource(src, { type: 'geojson', data: neighbors });

    // map.addLayer({
    //   'id': src,
    //   'type': 'fill',
    //   'source': src,
    //   'layout': {},
    //   'paint': {
    //   'fill-color' : 'transparent',
    //   'fill-outline-color': 'transparent'
    //   }
    // });

    // const lotcounts = neighbors.features.map(_ => _.properties.total_lots)
    // let minL = min(lotcounts) + 20
    // let maxL = max(lotcounts) - 20

    // map.addLayer(
    //   {
    //   'id': src+'-heat',
    //   'type': 'heatmap',
    //   'source': src,
    //   'maxzoom': 15,
    //   'paint': {
    //   // increase weight as diameter breast height increases
    //   'heatmap-weight': {
    //   'property': 'total_lots',
    //   'type': 'exponential',
    //   'stops': [
    //   [minL, 0.25],
    //   [maxL, 0.75]
    //   ]
    //   },
    //   // "heatmap-weight": {
    //   //   "type": "identity",
    //   //   "property": "total_lots"
    //   // },
    //   // increase intensity as zoom level increases
    //   'heatmap-intensity': {
    //   'stops': [
    //   [11, 1],
    //   [15, 3]
    //   ]
    //   },
    //   // use sequential color palette to use exponentially as the weight increases
    //   'heatmap-color': [
    //   'interpolate',
    //   ['linear'],
    //   ['heatmap-density'],
    //   0,
    //   'rgba(33,102,172,0)',
    //   0.2,
    //   'rgb(103,169,207)',
    //   0.4,
    //   'rgb(209,229,240)',
    //   0.6,
    //   'rgb(253,219,199)',
    //   0.8,
    //   'rgb(239,138,98)'
    //   ],
    //   // increase radius as zoom increases
    //   'heatmap-radius': {
    //   'stops': [
    //   [11, 15],
    //   [15, 20]
    //   ]
    //   },
    //   // decrease opacity to transition into the circle layer
    //   'heatmap-opacity': {
    //   'default': 1,
    //   'stops': [
    //   [14, 1],
    //   [15, 0]
    //   ]
    //   }
    //   }
    //   },
    //   src
    //   );

    // map.addLayer({
    //   id: 'neighborhood-point',
    //   type: 'circle',
    //   source: 'neighbors-hm',
    //   minzoom: 14,
    //   paint: {
    //     // increase the radius of the circle as the zoom level and dbh value increases
    //     'circle-radius': {
    //       property: 'dbh',
    //       type: 'exponential',
    //       stops: [
    //         [{ zoom: 15, value: 1 }, 5],
    //         [{ zoom: 15, value: 62 }, 10],
    //         [{ zoom: 22, value: 1 }, 20],
    //         [{ zoom: 22, value: 62 }, 50],
    //       ]
    //     },
    //     'circle-color': {
    //       property: 'dbh',
    //       type: 'exponential',
    //       stops: [
    //         [0, 'rgba(236,222,239,0)'],
    //         [10, 'rgb(236,222,239)'],
    //         [20, 'rgb(208,209,230)'],
    //         [30, 'rgb(166,189,219)'],
    //         [40, 'rgb(103,169,207)'],
    //         [50, 'rgb(28,144,153)'],
    //         [60, 'rgb(1,108,89)']
    //       ]
    //     },
    //     'circle-stroke-color': 'white',
    //     'circle-stroke-width': 1,
    //     'circle-opacity': {
    //       stops: [
    //         [14, 0],
    //         [15, 1]
    //       ]
    //     }
    //   }
    // }, 'neighbors-hm');
  }

  loadMap() {
    map = new mapboxgl.Map({
      container: this.mapContainerRef.current,
      style: mute_basemap,
      center: [this.state.lng, this.state.lat],
      //center: [-73.9646518861252, 40.5892123321491],
      zoom: this.state.zoom,
      minZoom: 2,
      maxZoom: 19,
    });

    // if ($(window).width() <= 768) {
    //   map.on('dragstart', (event) => {
    //     if (event.originalEvent && 'touches' in event.originalEvent && event.originalEvent.touches.length >= 2) {
    //       map.dragPan.enable();
    //     } else {
    //       map.dragPan.disable();
    //     }
    //   });
    // }

    const nav = new mapboxgl.NavigationControl();
    map.addControl(nav, "top-left");

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      // Select which mapbox-gl-draw control buttons to add to the map.
      controls: {
        polygon: true,
        trash: true,
      },
      // Set mapbox-gl-draw to draw by default.
      // The user does not have to click the polygon control button first.
      //defaultMode: 'draw_polygon'
    });
    map.addControl(draw, "top-left");

    map.on("draw.create", updateArea.bind(this));
    map.on("draw.delete", updateArea.bind(this));
    map.on("draw.update", updateArea.bind(this));

    function updateArea(e) {
      if (e.type === "draw.create") {
        if (previousDrawId !== null) {
          draw.delete(previousDrawId);
        }

        previousDrawId = draw.getAll().features[0].id;
        //draw.deleteAll()
      }
      if (e.type === "draw.delete") polygonBounds = null;
      else {
        const data = draw.getAll();
        if (data.features.length > 0) {
          //let geom = data.features[0].geometry
          //geom = bbox(geom)

          //polygonBounds = new mapboxgl.LngLatBounds();
          //polygonBounds.extend(geom)

          polygonBounds = data.features[0].geometry.coordinates[0];
        } else {
        }
      }
    }

    // const geoLocateControl = new mapboxgl.GeolocateControl({
    //   positionOptions: {
    //     enableHighAccuracy: true,
    //   },
    //   trackUserLocation: true,
    // });
    // map.addControl(geoLocateControl, 'bottom-left');

    map.on("load", this.initMapWithRouting.bind(this));

    map.on("load", this.loadExtraLayers);

    map.on("load", this.getNeighborhoods);
    map.on("load", this.getFloodZones);
    map.on("load", this.getParcels);

    map.on("move", () => {
      this.setState({
        lng: map.getCenter().lng.toFixed(4),
        lat: map.getCenter().lat.toFixed(4),
        zoom: map.getZoom().toFixed(2),
      });
      //console.log(this.state.zoom)
    });

    map.on("moveend", ({ originalEvent }) => {
      if (originalEvent) {
        this.getNeighbors();
        //this.getEmptyStreets();
      } else {
        if (!alreadyFlown) {
          alreadyFlown = true;
          this.getNeighbors();
        }
      }
    });

    // map.on('movestart', () => {
    //   map.setLayoutProperty('pluto', 'visibility', 'none');
    // })

    // map.on('moveend', () => {
    //   map.setLayoutProperty('pluto', 'visibility', 'visible');
    // })

    //map.on('load', this.addLotLayers);

    const _this = this


    map.on("click", "parcels", function (e) {
      //console.log(e)
      //const features = this.mapContainerRef.current.queryRenderedFeatures(e.point);
      //console.log(features)
      //console.log(e.features[0].properties.bbl)
      const { mapMode } = _this.state;
      if (mapMode === MapMode.COMPS) {
        return;
      }
      _this.loadLotByBBL(e.features[0].properties.propertyid, "fa" /*e.features[0].properties.type*/);
    });

      //     var label = $('#hlneighbor_info');
    map.on("mousemove", "parcels", function (e) {
      //console.log(e)
      map.getCanvas().style.cursor = "pointer";
      var properties = e.features[0].properties;
      _this.setState({ hoverLot: properties });
      // label_content = $('#hlneighbor_info_content'),
      // label_bldg_class = $('#hlneighbor_info_bclass'),
      // label_extra_info = $('#hlneighbor_info_extra'),
      // hltext = '<h4>' + properties.address + '</h4>';
      // if (properties.property_class) {
      //   label_bldg_class.html(properties.property_class);
      //   label.addClass('blgd_class');
      // } else {
      //   label_bldg_class.html('');
      //   label.removeClass('blgd_class');
      // }
      // if (properties.extra_info) {
      //   label_extra_info.html(properties.extra_info);
      //   label.addClass('extra_info');
      // } else {
      //   label_extra_info.html('');
      //   label.removeClass('extra_info');
      // }
      // label_content.html(hltext);
      // label.css('display', 'inline-block');
    });

    map.on("mouseleave", "parcels", function (e) {
      map.getCanvas().style.cursor = "";
      _this.setState({ hoverLot: null });
      // label.hide();
    });

    var hoveredStateId = null;
    map.on("mousemove", "parcels", function (e) {
      if (e.features.length > 0) {
        if (hoveredStateId) {
          map.setFeatureState(
            { source: "parcels", sourceLayer: 'parcel_layer', id: hoveredStateId },
            { hover: false }
          );
        }
        hoveredStateId = e.features[0].id;
        map.setFeatureState(
          { source: "parcels", sourceLayer: 'parcel_layer', id: hoveredStateId },
          { hover: true }
        );
      }
    });

    map.on("mouseleave", "parcels", function () {
      if (hoveredStateId) {
        map.setFeatureState(
          { source: "parcels", sourceLayer: 'parcel_layer', id: hoveredStateId },
          { hover: false }
        );
      }
      hoveredStateId = null;
    });
   
    map.on("click", "neighbors-pluto", function (e) {
      //console.log(e)
      //const features = this.mapContainerRef.current.queryRenderedFeatures(e.point);
      //console.log(features)
      //console.log(e.features[0].properties.bbl)
      const { mapMode } = _this.state;
      if (mapMode === MapMode.COMPS) {
        return;
      }
      _this.loadLotByBBL(e.features[0].properties.bbl, "pluto" /*e.features[0].properties.type*/);
    });

      //     var label = $('#hlneighbor_info');
    map.on("mousemove", "neighbors-pluto", function (e) {
      //console.log(e)
      map.getCanvas().style.cursor = "pointer";
      var properties = e.features[0].properties;
      _this.setState({ hoverLot: properties });
      // label_content = $('#hlneighbor_info_content'),
      // label_bldg_class = $('#hlneighbor_info_bclass'),
      // label_extra_info = $('#hlneighbor_info_extra'),
      // hltext = '<h4>' + properties.address + '</h4>';
      // if (properties.property_class) {
      //   label_bldg_class.html(properties.property_class);
      //   label.addClass('blgd_class');
      // } else {
      //   label_bldg_class.html('');
      //   label.removeClass('blgd_class');
      // }
      // if (properties.extra_info) {
      //   label_extra_info.html(properties.extra_info);
      //   label.addClass('extra_info');
      // } else {
      //   label_extra_info.html('');
      //   label.removeClass('extra_info');
      // }
      // label_content.html(hltext);
      // label.css('display', 'inline-block');
    });

    map.on("mouseleave", "neighbors-pluto", function (e) {
      map.getCanvas().style.cursor = "";
      _this.setState({ hoverLot: null });
      // label.hide();
    });

    var hoveredStateId = null;
    map.on("mousemove", "neighbors-pluto", function (e) {
      if (e.features.length > 0) {
        if (hoveredStateId) {
          map.setFeatureState(
            { source: "neighbors-pluto", id: hoveredStateId },
            { hover: false }
          );
        }
        hoveredStateId = e.features[0].id;
        map.setFeatureState(
          { source: "neighbors-pluto",  id: hoveredStateId },
          { hover: true }
        );
      }
    });

    map.on("mouseleave", "neighbors-pluto", function () {
      if (hoveredStateId) {
        map.setFeatureState(
          { source: "neighbors-pluto", id: hoveredStateId },
          { hover: false }
        );
      }
      hoveredStateId = null;
    });
  }

  removeRadiusCircles() {
    removeSourceFromMap(map, "quarter_radius");
    removeSourceFromMap(map, "half_radius");
    removeSourceFromMap(map, "three_quarter_radius");
    removeSourceFromMap(map, "one_radius");

    removeSourceFromMap(map, "north_line");
    removeSourceFromMap(map, "south_west_line");
    removeSourceFromMap(map, "south_east_line");
  }

  setCircleRadius(coordinates) {
    this.removeRadiusCircles();
    let totalDistance = Settings.extendedCompsRadius;
    if (totalDistance < 1) totalDistance = 1;

    map.addSource(
      "quarter_radius",
      createGeoJSONCircle(coordinates, totalDistance * 0.25)
    );
    map.addSource(
      "half_radius",
      createGeoJSONCircle(coordinates, totalDistance * 0.5)
    );
    map.addSource(
      "three_quarter_radius",
      createGeoJSONCircle(coordinates, totalDistance * 0.75)
    );
    map.addSource(
      "one_radius",
      createGeoJSONCircle(coordinates, totalDistance * 1)
    );

    addCircleToMap(
      map,
      "one_radius",
      bucketColor(CompBucketOption.HIGH)
    );
    addCircleToMap(
      map,
      "three_quarter_radius",
      bucketColor(CompBucketOption.MIDDLEHIGH)
    );
    addCircleToMap(
      map,
      "half_radius",
      bucketColor(CompBucketOption.MIDDLELOW)
    );
    addCircleToMap(
      map,
      "quarter_radius",
      bucketColor(CompBucketOption.LOW)
    );

    map.addSource("north_line", createGeoJSONLine(coordinates, totalDistance));
    map.addSource(
      "south_west_line",
      createGeoJSONLine(coordinates, totalDistance, LineDirection.SOUTH_WEST)
    );
    map.addSource(
      "south_east_line",
      createGeoJSONLine(coordinates, totalDistance, LineDirection.SOUTH_EAST)
    );

    addLineToMap(map, "south_east_line");
    addLineToMap(map, "south_west_line");
    addLineToMap(map, "north_line");
  }

  async componentDidMount() {
    //window.addEventListener("resize", this.resize.bind(this))
    this.resize();
    setTimeout(() => {
      this.loadMap();
    }, 250);
  }

  componentWillUnmount() {
    //window.removeEventListener("resize", this.resize.bind(this))
  }

  resize() {
    this.setState({ isMobile: window.innerWidth <= 760 });
  }

  loadExtraLayers() {
    return;
    // TODO:: FIX TO LOAD THROUGH MAPBOX SOURCES
    //const sourceSubwayStations = new carto.source.Dataset('subway_stations');
    //const vizSubwayStations = new carto.Viz()

    //const layerSubwayStations = new carto.Layer('Subway Stations', sourceSubwayStations, vizSubwayStations)
    //layerSubwayStations.addTo(map)

    //const sourceSubwayLines = new carto.source.GeoJSON(subwayLines);
    /*const vizSubwayLines = new carto.Viz(`
    width: 1
    filter: zoom()>12
    color: ramp(buckets($rt_symbol, ${subway_symbols}),${subway_colors})
    `)*/

    /*const layerSubwayLines = new carto.Layer('Subway Lines', sourceSubwayLines, vizSubwayLines)
    layerSubwayLines.addTo(map)
*/
    //const sourceSubwayEntrances = new carto.source.Dataset('subway_entrances');
    //const vizSubwayEntrances = new carto.Viz()

    //const layerSubwayEntrances = new carto.Layer('Subway Entrances', sourceSubwayEntrances, vizSubwayEntrances)
    //layerSubwayEntrances.addTo(map)
  }

  handleSidePanel() {
    const { visible } = this.state;

    if (visible) {
      this.handleRightPanelClose();
    }

    this.setState({ visible: !visible });
  }

  handleCompsSidebarPanel() {
    const { compsSidebarVisible } = this.state;

    if (!compsSidebarVisible) {
      this.handleCompsPanelOpen();
    } else {
      this.handleCompsPanelClose();
    }

    this.setState({ compsSidebarVisible: !compsSidebarVisible });
  }

  handleFilterPanel() {
    const { filterVisible } = this.state;

    // if (filterVisible) {
    //   this.handleFilterPanelClose()
    // }

    this.setState({ filterVisible: !filterVisible });
  }

  handleCompsTable() {
    const { containerClasses, compsVisible } = this.state;

    if (!compsVisible) {
      addToArray(containerClasses, "comps-visible");
    } else {
      removeFromArray(containerClasses, "comps-visible");
    }

    this.setState({ compsVisible: !compsVisible, containerClasses });
  }

  handleProspectingTable() {
    const { containerClasses, prospectingVisible } = this.state;

    if (!prospectingVisible) {
      addToArray(containerClasses, "prospecting-visible");
    } else {
      removeFromArray(containerClasses, "prospecting-visible");
    }

    this.setState({
      prospectingVisible: !prospectingVisible,
      containerClasses,
    });
  }

  handleLotLayerSelection(lot) {
    if (
      this.state.mapMode === MapMode.SEARCH ||
      this.state.mapMode === MapMode.PROSPECTING
    )
      if (!marker) {
        marker = new mapboxgl.Marker({ color: "blue" });

        marker.setLngLat({ lng: lot.longitude, lat: lot.latitude });

        marker.addTo(map);
      } else {
        marker.setLngLat({ lng: lot.longitude, lat: lot.latitude });
      }
  }

  handleSecondaryLotLayerSelection(lot) {
    if (this.state.mapMode === MapMode.COMPS)
      if (!secondaryMarker) {
        secondaryMarker = new mapboxgl.Marker({ color: "#21ba45" });

        secondaryMarker.setLngLat({ lng: lot.longitude, lat: lot.latitude });

        secondaryMarker.addTo(map);
      } else {
        secondaryMarker.setLngLat({ lng: lot.longitude, lat: lot.latitude });
      }
  }

  getProperty(propertyId) {
    return cachedProperties[propertyId];
  }
  getPropertyByAddress(address) {
    return this.getProperty(cachedBBLs[address]);
  }

  setSelectedProperty(obj, completedAll) {
    // if (completedAll) {
    //   this.setState({selectedLot: obj.properties, isLoading: !completedAll })
    // }

    this.setUrl(obj);

    this.setState({
      isLoading: false
    });
    this.setState({ selectedLot: obj, isLoading: !completedAll, historicalChartData: null });
    localStorage.setItem("selectedLot", obj.propertyId);

    this.handleLotLayerSelection(obj);
    if (!alreadyMoved) {
      alreadyMoved = true;

      this.flyTo([obj.longitude, obj.latitude]);
    }
  }

  resetRightPanel() {
    this.setState({ selectedLot: null });

    localStorage.removeItem("selectedLot");
    this.openRightPanel();
  }

  openRightPanel() {
    this.setState({ visible: true });
  }

  handleRightPanelOpen() {
    const { containerClasses } = this.state;

    addToArray(containerClasses, "right-panel");

    this.setState({ containerClasses });
  }

  handleCompsPanelOpen() {
    const { containerClasses } = this.state;

    addToArray(containerClasses, "comps-panel");

    this.setState({ containerClasses });
  }

  handleCompsPanelClose() {
    const { containerClasses } = this.state;

    removeFromArray(containerClasses, "comps-panel");

    this.setState({ containerClasses });
  }

  handleRightPanelClose() {
    const { containerClasses } = this.state;

    removeFromArray(containerClasses, "right-panel");

    this.setState({ containerClasses });
  }

  handleFilterPanelOpen() {
    const { containerClasses } = this.state;

    addToArray(containerClasses, "filter-panel");

    this.setState({ containerClasses });
  }

  handleFilterPanelClose(event, data) {
    if (event) return;
    const { containerClasses } = this.state;

    removeFromArray(containerClasses, "filter-panel");

    this.setState({ containerClasses });
  }

  handleProspectingFilterPanel() {
    const { prospectingFilterVisible } = this.state;

    // if (prospectingFilterVisible) {
    //   this.handleFilterPanelClose()
    // }

    this.setState({ prospectingFilterVisible: !prospectingFilterVisible });
  }

  handleAddToWatchlist(event, lot) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "staging") {
      // dev code
      lot.isWatchlistActive = !lot.isWatchlistActive;
      this.setState({ selectedLot: lot });
    } else {
      var url = `${property_api_add_to_watchlist_url}?bbl=${lot.propertyId}`;
      this.handleWatchlistChange(url, lot);
    }
  }

  handleAddToOwnership(event, lot) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "staging") {
      // dev code
      lot.isWatchlistOwned = !lot.isWatchlistOwned;
      this.setState({ selectedLot: lot });
    } else {
      var url = `${property_api_add_to_ownership_url}?bbl=${lot.propertyId}`;
      this.handleWatchlistChange(url, lot);
    }
  }

  handleAddToContract(event, lot) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "staging") {
      // dev code
      lot.isWatchlistContract = !lot.isWatchlistContract;
      this.setState({ selectedLot: lot });
    } else {
      var url = `${property_api_add_to_contract_url}?bbl=${lot.propertyId}`;
      this.handleWatchlistChange(url, lot);
    }
  }

  handleWatchlistChange(url, lot) {
    const _this = this;
    this.fetchData(url, (url, result) => {
      if (lot.propertyId.toString() === result.propertyId.toString()) {
        lot.isWatchlistActive = result.active;
        lot.isWatchlistOwned = result.isOwned;
        lot.isWatchlistContract = result.isContract;
        _this.setState({ selectedLot: lot });
      }
    });
  }

  async handleNotesChange(event, lot) {
    const val = event.target.value;

    if (lot.lotNotes !== val) {
      lot.lotNotes = val;

      let result = await makePutRequest(
        `${property_api_update_lot_notes_url}?propertyId=${lot.propertyId}`,
        val
      );

      if (result) {
        this.setState({ selectedLot: lot });
      }
    }
  }

  async handleWideStreetChange(value, lot) {
    if (lot.streets.length === 1) {
      lot.isWideStreet = value;

      this.setState({ selectedLot: lot });
      let result = await makePutRequest(
        `${property_api_update_wide_street_url}?id=${lot.streets[0].streetId}`,
        value
      );
    }
  }

  fetchData(url, callback, include) {
    makeGetRequestWithCallback(
      url,
      (results) => {
        return callback(url, results);
      },
      include
    );
  }


  isFetchedBBL(propertyId) {
    const { fetchedBBL } = this.state;
    if (!fetchedBBL || !propertyId) return false;
    return fetchedBBL.toString() === propertyId.toString();
  }

  async loadLotBy(query) {
    
    var lot_url = property_api_get_lot_url;
    var url = `${lot_url}?propertyId=${query}`;

    alreadyFlown = false;
    alreadyMoved = false;
    this.setState({
      isLoading: true,
      fetchedBBL: query,
    });

    const lot = await makeGetRequest(url);

    this.handleLotLoad(lot)

    return lot;
  }

  setUrl(lot) {
    let identifier = Lot.isPluto(lot) ? 'bbl' : Lot.isFA(lot) ? 'pid' : Lot.isRE(lot) ? 'rid' : ''
    window.history.pushState({}, null, `/${identifier}/${lot.propertyId}`);
  }

  async handleLotLoad(lot) {
    if (lot) {
      if (!this.isFetchedBBL(lot.propertyId)) {
        return;
      }

      this.setSelectedProperty(lot, false);

      if (Lot.isPluto(lot)) {
        const extraUrl = `${property_api_external_information_url}?propertyId=${
          lot.propertyId
        }&address=${Lot.fullAddress(lot)}`;

        var extraResult = await makeGetRequest(extraUrl);

        if (extraResult) {
          if (!this.isFetchedBBL(lot.propertyId)) {
            return;
          }

          lot.vacateOrder = extraResult.vacateOrder;
          lot.hasAEP = extraResult.hasAEP;
          lot.annualTax = extraResult.annualTax;
          lot.propertyBillUrl = extraResult.propertyBillUrl;
          lot.stats = extraResult.stats;
          lot.zestimate = extraResult.zestimate;
          lot.balancePastDue = extraResult.balancePastDue;

          cachedProperties[lot.propertyId] = lot;
          this.setSelectedProperty(lot, true);
        }
      } else if (Lot.isFA(lot)) {
        const cityDataResults = await getCityData(lot.propertyId)

        if (cityDataResults) {
          // Maybe cache data results by style/unit
          lot.cityData = cityDataResults
        }

        const extraUrl = `${property_api_zestimate_url}?address=${Lot.fullAddress(
          lot
        )}`;

        var extraResult = await makeGetRequest(extraUrl);

        if (extraResult) {
          if (!this.isFetchedBBL(lot.propertyId)) {
            return;
          }

          lot.zestimate = extraResult.zestimate;

          cachedProperties[lot.propertyId] = lot;
          this.setSelectedProperty(lot, true);
        }
      } else {
        const extraUrl = `${property_api_zestimate_url}?address=${Lot.fullAddress(
          lot
        )}`;

        var extraResult = await makeGetRequest(extraUrl);

        if (extraResult) {
          if (!this.isFetchedBBL(lot.propertyId)) {
            return;
          }

          lot.zestimate = extraResult.zestimate;

          cachedProperties[lot.propertyId] = lot;
          this.setSelectedProperty(lot, true);
        }
      }
    } else {
      alert("Invalid PropertyId");
      this.setState({
        isLoading: false,
        fetchedBBL: null,
      });
      this.handleSidePanel();
    }
  }

  loadLotByBoroBlockLot(boro, block, lot) {
    this.resetRightPanel();
    const bbl = boro + block.padStart(5, "0") + lot.padStart(4, "0");
    this.loadLotBy(bbl);
  }

  loadLotByBBL(bbl) {
    if (
      this.state.selectedLot != null &&
      bbl.toString() === this.state.selectedLot.propertyId.toString()
    ) {
      return this.state.selectedLot;
    }
    this.resetRightPanel();
    if (cachedProperties[bbl] != null) {
      const tempLot = this.getProperty(bbl);
      this.setSelectedProperty(tempLot, true);
      return tempLot;
    } else {
      return this.loadLotBy(bbl);
    }
  }

  flyTo(location, min = 14) {
    let zoom = map.getZoom();

    if (zoom < min) {
      zoom = 18;
    }

    map.flyTo({
      center: location,
      zoom: zoom,
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    });
  }

  // handleResultSelect = (e, data) => {
  //   const {result} = data || {}
  //   if (result) {
  //     this.loadLotByBBL(result.id, DataSetType.REAL_ESTATE_API)
  //   }
  // }

  // handleSearchChange = (event) => {
  //   const {value} = event?.target || {}
  //   if (value && value.length >= 2) {
  //     makeGetRequestWithCallback(property_api_urbyn_search_address_url+"?search="+value, (results) => {
  //       if (results) {
  //         const parcels = results.filter(_ => _.searchType === 'A');
  //         const {searchState} = this.state
  //         searchState.results = parcels
  //         this.setState({searchState})
  //       }
  //     });
  //   }
  // }

  handleSearchChange = (written, address, parts) => {
    //console.log(written)
    //console.log(address)
    const { mapMode } = this.state;
    if (mapMode === MapMode.COMPS || mapMode === MapMode.COMBINEDCOMPS) {
      this.turnOffComps();
    }

    if (!address || address.length === 0) return

    //console.log(written, parts)
    var search = getParcelAddressQueryParams(written, address, parts)
    
    let cacheAddress = search//searchAddress + " " + county + " " + state

    alreadyFlown = false;
    alreadyMoved = false;
    this.resetRightPanel();
    if (cachedBBLs[cacheAddress] != null) {
      const tempLot = this.getPropertyByAddress(cacheAddress);
      this.setSelectedProperty(tempLot, true);
    } else {
     
      this.setState({isLoading: true})

      makeGetRequestWithCallback(`${parcel_get_url}?${search}`,
      (result) => {
        this.setState({
          isLoading: false,
          fetchedBBL: result?.propertyId
        }, () => {
          if (!result || result.errorMessage) {
            alert(result.errorMessage ?? 'Error')
            return
          }
        
          cachedProperties[result.propertyId] = result
          cachedBBLs[cacheAddress] = result.propertyId
          this.setSelectedProperty(result, false);
          this.handleLotLoad(result)
        });
      })
    }
  };

  handleCompsSearch = () => {
    resetUnitFilter();
  };

  // applyFilters = async () => {
  //   const { mapMode, compsData, compsMortgageData, selectedLot } = this.state
  //   if (mapMode === MapMode.COMPS) {
  //     var compsToChange;
  //     var mortgageCompsToChange;

  //     const dResult = await applyCompFilters(map, selectedLot)
  //     if (dResult) {
  //       if (dResult.renewed) {
  //         compsToChange = dResult.data
  //       } else {
  //         compsData.comps = dResult.data
  //         compsToChange = compsData
  //       }
  //     }

  //     var dCalcResult = runCalculate(selectedLot, compsToChange, false, true)
  //     compsToChange.comps = dCalcResult.comps

  //     const mResult = await applyCompFilters(map, selectedLot)
  //     if (mResult) {
  //       if (mResult.renewed) {
  //         mortgageCompsToChange = mResult.data
  //       } else {
  //         compsMortgageData.comps = mResult.data
  //         mortgageCompsToChange = compsMortgageData
  //       }
  //     }

  //     var mCalcResult = runCalculate(selectedLot, mortgageCompsToChange, true, true)

  //     mortgageCompsToChange.comps = mCalcResult.comps

  //     runERMethod()
  //     this.setState({
  //       compsData: compsToChange,
  //       compsMortgageData: mortgageCompsToChange,
  //       compsOverride: true, compsCalc: dCalcResult.compsCalc
  //     })
  //   }
  // }

  turnOffComps = (callback) => {
    const { containerClasses } = this.state;
    Settings.extendedCompsRadius = 1;
    removeComps(map);
    if (secondaryMarker) {
      secondaryMarker.remove();
      secondaryMarker = null;
    }

    this.removeRadiusCircles();

    removeFromArray(containerClasses, "comps-visible");

    cachedCompsData = {};
    cachedChart = {};

    this.setState(
      {
        mapMode: MapMode.SEARCH,
        lenderProspectingMode: LenderProspectingMode.NONE,
        compsBucket: CompBucketOption.HIGH,
        compType: CompTypeOption.NONE,
        compsLoading: false,
        compsVisible: false,
        compsData: {},
        combinedCompData: {},
        compsPrimaryLot: {},
        salesChart: null,
        compsDate: today(),
        forceCombined: false,
        compsVersion: null,
        containerClasses
      },
      callback
    );
  };

  handleCombinedComps = () => {
    const { selectedLot, mapMode } = this.state;
    if (!selectedLot) {
      return;
    }
    if (mapMode === MapMode.COMBINEDCOMPS) {
      this.turnOffComps(() => {
        this.setSelectedProperty(selectedLot, true);
        this.flyTo([selectedLot.longitude, selectedLot.latitude], 16);
      });
    } else {
      this.runCombinedComps();
    }
  }

  handleCombinedForcedComps = () => {
    const { selectedLot, mapMode } = this.state;
    if (!selectedLot) {
      return;
    }
    if (mapMode === MapMode.COMPS || mapMode === MapMode.COMBINEDCOMPS) {
      this.turnOffComps(() => {
        this.setSelectedProperty(selectedLot, true);
        this.flyTo([selectedLot.longitude, selectedLot.latitude], 16);
      });
    } else {
      this.setState({forceCombined: true}, () => {
        this.runComps(CompTypeOption.DEED);
      })
    }
  }

  handleCompsVersion = (version) => {
    const { selectedLot, mapMode } = this.state;
    if (!selectedLot) {
      return;
    }
    if (mapMode === MapMode.COMPS || mapMode === MapMode.COMBINEDCOMPS) {
      this.turnOffComps(() => {
        this.setSelectedProperty(selectedLot, true);
        this.flyTo([selectedLot.longitude, selectedLot.latitude], 16);
      });
    } else {
      this.setState({compsVersion: version}, () => {
        this.runComps(CompTypeOption.DEED);
      })
    }
  }

  handleCompsSearch = () => {
    const { selectedLot, mapMode } = this.state;
    if (!selectedLot) {
      return;
    }
    if (mapMode === MapMode.COMPS) {
      this.turnOffComps(() => {
        this.setSelectedProperty(selectedLot, true);
        this.flyTo([selectedLot.longitude, selectedLot.latitude], 16);
      });
    } else {
      this.runComps(CompTypeOption.DEED);
    }
  };

  handleHistoricalComps = () => {
    const { selectedLot, historicalChartData } = this.state;
    if (!selectedLot) {
      return;
    }
    if (historicalChartData) {
      this.setState({historicalChartData: null})
      return
    }
    this.setState({loadingHistory: true})
    runHistoricalComps(selectedLot, (results) => {
      this.setState({loadingHistory: false})
      if (results) {
        let bestClusters = results.map(calc => {
          let buckets = calc.buckets
          if (buckets.length > 0) {
            buckets.sort((a, b) => a?.lqc < b?.lqc ? 1 : -1)
            let best = buckets[0]
            return {
              x: new Date(calc.date),
              y: best?.price,
              color: bucketColor(best?.bucket)
            }
            /*let lowBuckets = buckets.filter(_ => _?.z < -.75) //filter(_ => [CompBucketOption.LOW,CompBucketOption.MIDDLELOW].includes(_.bucket))
            let middleBuckets = buckets.filter(_ => _?.z >= -.75 && _?.z <= .75)//filter(_ => [CompBucketOption.MIDDLELOW,CompBucketOption.MIDDLE,CompBucketOption.MIDDLEHIGH].includes(_.bucket))
            let highBuckets = buckets.filter(_ => _?.z > .75)//filter(_ => [CompBucketOption.HIGH,CompBucketOption.MIDDLEHIGH].includes(_.bucket))
            
            lowBuckets.sort((a, b) => a?.lqc < b?.lqc ? 1 : -1)
            middleBuckets.sort((a, b) => a?.lqc < b?.lqc ? 1 : -1)
            highBuckets.sort((a, b) => a?.lqc < b?.lqc ? 1 : -1)

            let bestLow = lowBuckets.length > 0 ? lowBuckets[0] : null
            let bestMiddle = middleBuckets.length > 0 ? middleBuckets[0] : null
            let bestHigh = highBuckets.length > 0 ? highBuckets[0] : null
            return [
              {
                x: new Date(calc.date),
                y: bestLow?.price,
                color: bucketColor(bestLow?.bucket)
              },
              {
                x: new Date(calc.date),
                y: bestMiddle?.price,
                color: bucketColor(bestMiddle?.bucket)
              },
              {
                x: new Date(calc.date),
                y: bestHigh?.price,
                color: bucketColor(bestHigh?.bucket)
              }
            ]*/
          }
          return null
        })
        bestClusters = bestClusters.filter(_ => _ && _.y)//bestClusters.flat().filter(_ => _ && _.y)
        let chartData = []
        EXTENDED_COMP_BUCKETS.forEach(bucket => {
          let bucketData = []
          results.forEach(calc => {
            const {buckets} = calc
            let bucketFound = buckets.find(_ => _.bucket === bucket)
            if (bucketFound) {
              //let d = calc.date.split('/')
              bucketData.push({
                x: new Date(calc.date),//`${d[2]}/${d[0]}`,
                y: bucketFound.price,
                lqc: bucketFound.lqc,
                z: bucketFound.z
              })
            }
          })
          chartData.push({
            bucket: bucket,
            data: bucketData,
            color: bucketColor(bucket)
          })
        })
        
        this.setState({historicalChartData: {
          chartData,
          bestClusters
        }})
      }
    })
  }

  runCityComps = (type, bedrooms = null) => {
    const {
      selectedLot,
      compType,
      compsBucket,
      compsVersion
    } = this.state;
    if (compType === type) return;
    const _this = this;
    runCityComps(
      selectedLot,
      {
        dataset: selectedLot.type,
        version: compsVersion
      },
      (compResults, canceled) => {
        if (!canceled) {
          const {compsData, compOptions} = compResults || {}
          //console.log("Got New Comps")
          _this.finishSettingUpComps(selectedLot, compsData, {
            compsBucket,
            type,
            bedrooms,
          });
        }
      }
    )
    
  }

  runCombinedComps = () => {
    const {
      selectedLot,
      prospectingFilterVisible,
      prospectingVisible,
      compsDate,
      containerClasses,
      compsVersion
    } = this.state;
    if (prospectingFilterVisible) this.handleProspectingFilterPanel();
    if (prospectingVisible) {
      this.handleProspectingTable();
    }
    addToArray(containerClasses, "comps-visible");
    addToArray(containerClasses, "comps-panel");
    
    this.setState(
      {
        mapMode: MapMode.COMBINEDCOMPS,
        compsVisible: true,
        compsLoading: true,
        compsSidebarVisible: true,
        compsOverride: false,
        compsData: {},
      },
      () => {
        runCombinedComps(
          selectedLot,
          {
            date: compsDate,
            ds: selectedLot.type,
            version: compsVersion
          },
          (compResults) => {
            if (compResults) {
              const {comps, cityComps, chartData, cityChartData, combinedComps} = compResults || {}

              if (comps) {
                var tdsChart = this.setupLTDSChart(
                  comps,
                  chartData
                );
                compResults.tdsChart = tdsChart
              }
              if (cityComps) {
                var cityTdsChart = this.setupLTDSChart(
                  cityComps,
                  cityChartData
                );
                compResults.cityTdsChart = cityTdsChart
              }
              if (combinedComps) {
                var combinedTdsChart = this.setupLTDSChart(
                  combinedComps,
                  chartData
                );
                compResults.combinedTdsChart = combinedTdsChart
              }

              this.setState({combinedCompData: compResults, compsLoading: false})
            }
          }
        );
      }
    );
  }

  runComps = (type, bedrooms = null) => {
    const {
      selectedLot,
      containerClasses,
      prospectingFilterVisible,
      prospectingVisible,
      compsBucket,
      compType,
      compsData,
      rentCompBedroomMode,
      compsDate,
      forceCombined,
      compsVersion,
    } = this.state;
    if (compType === type && rentCompBedroomMode === bedrooms) return;
    if (prospectingFilterVisible) this.handleProspectingFilterPanel();
    if (prospectingVisible) {
      this.handleProspectingTable();
    }
    addToArray(containerClasses, "comps-visible");
    addToArray(containerClasses, "comps-panel");

    compsInitialized = false;

    if (!isEmpty(cachedCompsData[type]) && !isEmpty(cachedCompsData[type])) {
      let tmpComps = null;
      if (bedrooms && Lot.isPluto(selectedLot)) {
        if (!isEmpty(cachedCompsData[type][bedrooms])) {
          tmpComps = { ...cachedCompsData[type][bedrooms] };
          if (isEmpty(cachedCompsData[compType]))
            cachedCompsData[compType] = {};
          if (isEmpty(cachedCompsData[compType][rentCompBedroomMode]))
            cachedCompsData[compType][rentCompBedroomMode] = {};

          cachedCompsData[compType][rentCompBedroomMode] = {
            ...compsData,
          };
        }
      } else {
        tmpComps = { ...cachedCompsData[type] };
        if (isEmpty(cachedCompsData[compType]))
          cachedCompsData[compType] = {};

        cachedCompsData[compType] = { ...compsData };
      }
      if (tmpComps) {
        this.finishSettingUpComps(selectedLot, tmpComps, {
          compsBucket,
          type,
          bedrooms,
        });
        return;
      }
    }
    this.setState(
      {
        mapMode: MapMode.COMPS,
        compType: type,
        compsLoading: true,
        compsVisible: true,
        compsSidebarVisible: true,
        containerClasses,
        compsOverride: false,
        compsData: {},
      },
      () => {
        runComps(
          selectedLot,
          {
            date: compsDate,
            type,
            bedrooms: bedrooms,
            ds: selectedLot.type,
            forceCombined,
            version: compsVersion
          },
          (compResults, canceled) => {
            if (!canceled) {
              //console.log("Got New Comps")
              if (compResults && compResults.isCombined) {
                const {results} = compResults
                    const {comps, cityComps, chartData, cityChartData, combinedComps} = results || {}
       
                     if (comps) {
                       var tdsChart = this.setupLTDSChart(
                         comps,
                         chartData
                       );
                       results.tdsChart = tdsChart
                     }
                     if (cityComps) {
                       var cityTdsChart = this.setupLTDSChart(
                         cityComps,
                         cityChartData
                       );
                       results.cityTdsChart = cityTdsChart
                     }
                     if (combinedComps) {
                       var combinedTdsChart = this.setupLTDSChart(
                         combinedComps,
                         chartData
                       );
                       results.combinedTdsChart = combinedTdsChart
                     }
       
                     this.setState({
                      mapMode: MapMode.COMBINEDCOMPS,
                      compsVisible: true,
                      compsLoading: true,
                      compsSidebarVisible: true,
                      compsOverride: false,
                      compsData: {},
                      combinedCompData: results, compsLoading: false})
                
              } else {
                const {compsData, compOptions} = compResults || {}
                
                this.finishSettingUpComps(selectedLot, compsData, {
                  compsBucket,
                  type,
                  bedrooms,
                });
  
                
                if (type === CompTypeOption.DEED) {
                  runHistoricalCompPrice(selectedLot, compOptions, (closestBucket) => {
                    if (this.state.compType === CompTypeOption.DEED) {
                      if (closestBucket) {
                        const {lowBucket, middleBucket, highBucket} = compsData.buckets?.finalBuckets || {}
                        var buckets = [lowBucket, middleBucket, highBucket].filter(_ => _)
                        var closest = buckets.find(_ => _.bucket === closestBucket.bucket)
                        if (closest) {
                          if (closestBucket.isHigher) {
                            closest.isHistoricalHigher = true
                          } else {
                            closest.isHistoricalLower = true
                          }
      
                          this.setState({compsData})
                        }
                      }
                    }
                  })
                } 
              }
            }
          }
        );
      }
    );
  };

  finishSettingUpComps = (selectedLot, compsData, options) => {
    let { compsBucket, type, bedrooms = null } = options;
    removeCompMarkers(map);
    if (compsData && compsData.comps) {
      runCompMarkers(
        compsData.comps
          .sort((a, b) => (a.bucket < b.bucket ? 1 : -1))
          .filter(
            (_) =>
              _.bucket &&
              _.bucket.length >
                0 /*&& _.bucket !== CompBucketOption.REMOVED */ &&
              _.bucket !== CompBucketOption.SUBJECT &&
              _.bucketZScore >= -3 &&
              _.bucketZScore <= 3
          ),
        map,
        { isMobile: this.state.isMobile },
        this.handleMarkerClick.bind(this),
        this.handleHoverMarker.bind(this),
        this.handleCompPopup.bind(this),
        this.handleDeselectedComp.bind(this)
      );
    }

    if (type === CompTypeOption.RENT) {
      bedrooms =
        bedrooms == null &&
        selectedLot.bedrooms &&
        selectedLot.bedrooms.length > 0
          ? selectedLot.bedrooms[0]
          : bedrooms;
    }

    if(compsData && compsData.comps) {
      compsData.comps
      .filter((_) => _.bucket === compsBucket)
      .forEach((_) => (_.isSelected = true));
    }
    this.setState({
      compsPrimaryLot: selectedLot,
      compsData,
      compType: type,
      compsLoading: false,
      rentCompBedroomMode: bedrooms,
    });

    if (
      type === CompTypeOption.DEED ||
      type === CompTypeOption.MORTGAGE
    ) {
      //let _this = this;
      // this.fetchSales(
      //   selectedLot,
      //   compsData.calc,
      //   (middle) => {
      //     if (!compsData.calc) compsData.calc = {};
      //     //compsData.calc.estimatedMiddleCalc = middle
      //     _this.setState({ compsData });
      //   },
      //   type === CompTypeOption.DEED
      // );
    } else {
      this.setState({ salesChart: null });
    }
    if (compsData && compsData.comps) {
      var tdsChart = this.setupLTDSChart(
        bedrooms
          ? compsData.comps.filter((_) => _.rooms?.bedrooms === bedrooms)
          : compsData.comps,
        compsData?.stats?.chartData
      );
      this.setState({tdsChart})
    }

    setTimeout(() => {
      compsInitialized = true;
    }, 500);

    this.setCircleRadius([selectedLot.longitude, selectedLot.latitude]);

    if (!isEmpty(compsData)) {
      if (isEmpty(cachedCompsData[type])) cachedCompsData[type] = {};
      if (bedrooms) {
        if (isEmpty(cachedCompsData[type])) cachedCompsData[type] = {};
        cachedCompsData[type][bedrooms] = { ...compsData };
      } else {
        cachedCompsData[type] = { ...compsData };
      }
    }
  };

  handleCompsTableSwitch = (compsTableMode) => {
    this.setState({ compsTableMode });
  };

  handleCompsTableSwitch = (mode) => {
    const { compsData, compsBucket } = this.state;

    const  { comps } = compsData;

    comps
      .filter((_) => _.bucket === compsBucket)
      .forEach((_) => delete _.isSelected);
    comps
      .filter((_) => _.bucket === mode)
      .forEach((_) => (_.isSelected = true));

    this.setState({
      compsData,
      compsBucket: mode,
      switchedCompModes: true,
    });
  };

  handleCompsRentalSwitch = (bedrooms) => {
    this.runComps(CompTypeOption.RENT, bedrooms);
  };

  setCompRadiusChart = (data) => {
    var count = 0;

    const low = [];
    const middle = [];
    const high = [];

    const volumeData = [];
    for (var radius = 0.25; radius <= 1; radius += 0.25) {
      let d = data[count];

      if (d) {
        if (d.weightedLow > 0) low.push({ x: radius, y: d.weightedLow });
        if (d.weightedMiddle > 0)
          middle.push({ x: radius, y: d.weightedMiddle });
        if (d.weightedHigh > 0) high.push({ x: radius, y: d.weightedHigh });

        volumeData.push({
          x: radius,
          y: d.low + d.middle + d.high,
          type: "volume",
        });
      }

      count += 1;
    }

    let chartData = [];
    if (low.length > 0) {
      chartData.push({
        color: "#21ba45",
        data: low,
      });
    }
    if (middle.length > 0) {
      chartData.push({
        color: "#ffc100",
        data: middle,
      });
    }
    if (high.length > 0) {
      chartData.push({
        color: "#db2828",
        data: high,
      });
    }

    if (chartData.length > 0 || volumeData.length > 0) {
      this.setState({
        radiusChart: {
          chartData,
          volumeData,
          brushValue: searchFilters.radius,
        },
      });
    }
  };

  setupLTDSChart = (comps, stdInfo) => {

    let data = comps.map((_) => {
      let color = "";
      if (_.bucket && _.bucket.length > 0) {
        color = bucketColor(_.bucket);
      } else {
        color = "#ececec";
      }
      return getTDSChartData(_, color);
    });

    //data.sort((a,b) => a.x > b.x ? 1 : -1)
    data.sort((a, b) =>
      (a.bucket && a.bucket.length) > (b.bucket && b.bucket.length) ? 1 : -1
    );

    let movingAverage = getChartMovingAverage(data, 0, 100, 10);

    //console.log(movingAverage)

    return { data: data, stdInfo, movingAverage };
  };

  // fetchSales = async (target, calc, callback, isSales = true) => {
  //   const {selectedLot} = this.state
  //   if (!Lot.isPluto(selectedLot)) return;
  //   if (cachedChart[isSales]) {
  //     this.setState({ salesChart: cachedChart[isSales] });
  //     return;
  //   }
  //   const { neighborhood, borocode, unitsres } = target;
  //   const {
  //     lowBucketCalc,
  //     middleLowBucketCalc,
  //     middleBucketCalc,
  //     middleHighBucketCalc,
  //     highBucketCalc,
  //   } = calc || {};

  //   const bldgarea = Lot.bldgarea(target);

  //   const url = isSales
  //     ? property_api_get_neighborhood_sales_url
  //     : property_api_get_neighborhood_loans_url;

  //   const result = await makeGetRequest(
  //     `${url}?neighborhood=${neighborhood}&borocode=${borocode}`
  //   );
  //   if (result) {
  //     const sales = JSON.parse(result.chart);
  //     const saleData = sales.map((_) => _.sale);
  //     let years = saleData
  //       .map(property("years"))
  //       .reduce(flatten, [])
  //       .filter(onlyUnique);

  //     years.sort();

  //     let saleTrends = [];
  //     let unitSaleTrends = [];
  //     let movingAverage = [];
  //     let unitMovingAverage = [];

  //     let t = { ...getSalesForNeighborhood(saleData, neighborhood, borocode) };

  //     if (t && t.data) {
  //       years.reverse().forEach((year) => {
  //         let sale = {};
  //         let yearlyData = t.data.filter(
  //           (_) => _.year === year /*&& _.units === target.unitsres*/
  //         );
  //         sale.data = yearlyData.sort((a, b) => (a.y > b.y ? -1 : 1));
  //         sale.neighborhood = t.neighborhood;
  //         sale.year = year;

  //         saleTrends.push(sale);

  //         let unitSale = {};

  //         let unitYearlyData = [];
  //         if (unitsres === 3 || unitsres === 4) {
  //           unitYearlyData = t.data.filter(
  //             (_) => _.year === year && (_.units === 3 || _.units === 4)
  //           );
  //         } else
  //           unitYearlyData = t.data.filter(
  //             (_) => _.year === year && _.units === unitsres
  //           );

  //         unitSale.data = unitYearlyData.sort((a, b) => (a.y > b.y ? -1 : 1));
  //         unitSale.neighborhood = t.neighborhood;
  //         unitSale.year = year;

  //         unitSaleTrends.push(unitSale);
  //       });
  //     }

  //     let lowX =
  //       Math.floor(bldgarea / 100) * 100 * (1 - Settings.bldgareaPercent);
  //     let highX =
  //       Math.ceil(bldgarea / 100) * 100 * (1 + Settings.bldgareaPercent);

  //     let weightedAverages = [];
  //     let middleData = {};

  //     if (!isEmpty(lowBucketCalc)) {
  //       weightedAverages.push(
  //         getChartAvgSaleLine(
  //           lowX,
  //           highX,
  //           lowBucketCalc?.avgPSF,
  //           bucketColor(CompBucketOption.LOW)
  //         )
  //       );
  //     }

  //     if (!isEmpty(middleLowBucketCalc)) {
  //       weightedAverages.push(
  //         getChartAvgSaleLine(
  //           lowX,
  //           highX,
  //           middleLowBucketCalc?.avgPSF,
  //           bucketColor(CompBucketOption.MIDDLELOW)
  //         )
  //       );
  //     }

  //     if (!isEmpty(middleBucketCalc)) {
  //       middleData = getChartAvgSaleLine(
  //         lowX,
  //         highX,
  //         middleBucketCalc?.avgPSF,
  //         bucketColor(CompBucketOption.MIDDLE)
  //       );
  //       weightedAverages.push(middleData);
  //     }

  //     if (!isEmpty(middleHighBucketCalc)) {
  //       weightedAverages.push(
  //         getChartAvgSaleLine(
  //           lowX,
  //           highX,
  //           middleHighBucketCalc?.avgPSF,
  //           bucketColor(CompBucketOption.MIDDLEHIGH)
  //         )
  //       );
  //     }

  //     if (!isEmpty(highBucketCalc)) {
  //       weightedAverages.push(
  //         getChartAvgSaleLine(
  //           lowX,
  //           highX,
  //           highBucketCalc?.avgPSF,
  //           bucketColor(CompBucketOption.HIGH)
  //         )
  //       );
  //     }

  //     if (saleTrends.length > 0) {
  //       let allData = saleTrends
  //         .map(property("data"))
  //         .reduce(flatten, [])
  //         .sort((a, b) => (a.x > b.x ? 1 : -1));

  //       movingAverage = getChartMovingAverage(allData);

  //       let unitData = [];
  //       if (unitsres === 3 || unitsres === 4) {
  //         unitData = allData.filter((_) => _.units === 3 || _.units === 4);
  //       } else unitData = allData.filter((_) => _.units === unitsres);

  //       unitMovingAverage = getChartMovingAverage(unitData);

  //       let valuesBetween = getValuesbetween(unitData, lowX, highX);
  //       if (valuesBetween.length > 0) {
  //         // valuesBetween.sort((a,b) => a - b)
  //         // let half = Math.floor(valuesBetween.length / 2)
  //         // let median
  //         // if (valuesBetween.length % 2) {
  //         //   median = valuesBetween[half]
  //         // }
  //         // else {
  //         //   median = (valuesBetween[half - 1] + valuesBetween[half]) / 2
  //         // }
  //         let median = getMedian(valuesBetween);

  //         weightedAverages.push(
  //           getChartAvgSaleLine(
  //             lowX,
  //             highX,
  //             median,
  //             bucketColor(CompBucketOption.REMOVED)
  //           )
  //         );
  //       }
  //     }

  //     let found = false;

  //     let yValues = [];
  //     if (unitMovingAverage.length > 2 && middleData && middleData.data) {
  //       for (var i = 0; i < unitMovingAverage.length; i++) {
  //         let ma = unitMovingAverage[i];
  //         if (ma.x >= middleData.data[0].x) {
  //           //prevX = unitMovingAverage[i-1].x
  //           found = true;
  //         }
  //         if (found) yValues.push(ma.y);
  //         if (ma.x >= middleData.data[1].x) {
  //           //postX = unitMovingAverage[i-1].x
  //           found = false;
  //           break;
  //         }
  //       }
  //     } else {
  //       yValues = unitMovingAverage.map((_) => _.y);
  //     }

  //     let startsUnder = false;
  //     let startsOver = false;
  //     let goesUnder = false;
  //     let goesOver = false;

  //     //console.log(unitMovingAverage)
  //     //console.log(yValues)

  //     if (middleData && middleData.data) {
  //       let middleY = middleData.data[0].y;
  //       for (var x = 0; x < yValues.length; x++) {
  //         let value = yValues[x];
  //         if (x === 0) {
  //           if (value >= middleY) {
  //             startsOver = true;
  //           } else {
  //             startsUnder = false;
  //           }
  //         } else {
  //           if (value >= middleY) {
  //             if (startsUnder) goesOver = true;
  //           } else {
  //             if (startsOver) goesUnder = true;
  //           }
  //         }
  //       }
  //     }

  //     //console.log(startsUnder, startsOver, goesUnder, goesOver)
  //     if (yValues.length > 0) {
  //       let avgPSF = mean(yValues);
  //       callback &&
  //         callback({
  //           avgPSF,
  //           averageSalePrice: roundToNearest(avgPSF * bldgarea, 1),
  //           startsUnder,
  //           startsOver,
  //           goesUnder,
  //           goesOver,
  //         });
  //     }

  //     let chart = {
  //       saleTrends,
  //       unitSaleTrends,
  //       movingAverage,
  //       unitMovingAverage,
  //       weightedAverages: weightedAverages,
  //     };
  //     cachedChart[isSales] = chart;
  //     this.setState({ salesChart: chart });
  //   }
  // };

  handleSelectedRowsChange = (props) => {
    const { selectedRows } = props;
    const { compsData, selectedLot, compsLoading, compsOverride } = this.state;

    if (selectedLot === null) {
      return;
    }

    const cData = compsData;
    if (cData?.comps === null || cData?.comps === undefined) {
      return;
    }

    cData.comps.forEach((_) => {
      _.isSelected = selectedRows.find((__) => __.propertyId === _.propertyId) ? true : false;
    });

    let override = compsOverride;
    if (!compsOverride) {
      override = isDeedType(cData.type) && !compsLoading;
    }

    this.setState({ compsData, compsOverride: override }, () => {
      //this.calculateComps(!isDeedType(cData.type))
    });
  };

  
  handleDeselectedComp = (propertyId) => {
    const { compsData } = this.state;

    const cData = compsData;
    if (cData?.comps === null || cData?.comps === undefined) {
      return;
    }

    var comp = cData.comps.find((_) => _.propertyId === propertyId);

    if (comp) {
      comp.isSelected = !comp.isSelected;
    }

    changeMarkers(propertyId, "deselected");
    this.setState({ compsData }, () => {
      //this.calculateComps(!isDeedType(cData.type))
    });
  };

  handleCompPopup = (marker, lockPanel = false) => {
    var { selectedCompDetails, tmpSelectedCompDetails } = this.state;
    if (lockPanel) {
      // Mouse click
      if (tmpSelectedCompDetails && tmpSelectedCompDetails === marker) {
        // Remove sticky
        tmpSelectedCompDetails = null;
      } else {
        // Add sticky
        tmpSelectedCompDetails = selectedCompDetails;
      }
    } else {
      // Mouse enter/leave
      if (tmpSelectedCompDetails && marker === null) {
        marker = tmpSelectedCompDetails;
      }
    }
    this.setState({ selectedCompDetails: marker, tmpSelectedCompDetails });
  };

  compsAddressClicked = (propertyId) => {
    this.handleMarkerClick(propertyId);
  };

  prospectingAddressClicked = (propertyId) => {
    this.handleMarkerClick(propertyId);
  };

  prospectingLenderClicked = (type, summaryId, row) => {
    this.handleProspectingLenderClicked(type, summaryId, row);
  }

  prospectingBorrowerNameClicked = async (type, name) => {
    var model = {
      type,
      borrowerName: name
    };

    var success = await this.runProspectingBorrowerNameClicked(model);

    if (success) {
      this.addToBreadcrumbs({
        type: 'borrower-name',
        label: name,
        mode: LenderProspectingMode.BORROWER_TXS
      })
    }
  };

  runProspectingBorrowerNameClicked = async (model) => {
    this.setState({
      lenderProspectingLoading: true,
    });

    let result = await makePostRequest(
      `${property_api_run_borrower_txs_url}`,
      model
    );

    this.setState({
      lenderProspectingMode: LenderProspectingMode.BORROWER_TXS,
      lenderProspectingLoading: false,
      borrowerTransactionsData: result ? result : [],
    });

    return result !== null
  }

  prospectingBorrowerAddressClicked = async (type, address, unit, city, state, zip) => {
    var model = {
      type,
      borrowerAddress: address,
      borrowerUnit: unit,
      borrowerCity: city,
      borrowerState: state,
      borrowerZip: zip,
    };

    var success = await this.runProspectingBorrowerAddressClicked(model);

    if (success) {
      this.addToBreadcrumbs({
        type: 'borrower-address',
        label: address,
        mode: LenderProspectingMode.BORROWER_SUMMARY
      })
    }
  };

  runProspectingBorrowerAddressClicked = async (model) => {
    this.setState({
      lenderProspectingLoading: true,
    });

    let result = await makePostRequest(
      `${property_api_run_borrowers_by_address_url}`,
      model
    );

    this.setState({
      lenderProspectingMode: LenderProspectingMode.BORROWER_SUMMARY,
      lenderProspectingLoading: false,
      borrowerSummaryData: result ? result : [],
    });

    return result !== null
  }

  handleProspectingLenderClicked = async (type, summaryId, data) => {
    var model = {
      type,
      summaryId,
      individualId: data.individualId,
      entityId: data.entityId,
      lenderName: data.lenderName
    };

    var success = await this.runProspectingLenderClicked(model);

    if (success) {
      this.addToBreadcrumbs({
        type: 'lender',
        label: data.lenderName,
        mode: LenderProspectingMode.LENDER_TXS
      })
    } else {
      var refresh = await this.runLendersProspectSearch(cachedLendersProspectFilter);
      if (refresh) {
        return await this.handleProspectingLenderClicked(refresh.summaryId, data);
      }
    }
  }

  runProspectingLenderClicked = async (model) => {
    this.setState({
      lenderProspectingLoading: true,
    });

    let result = await makePostRequest(
      `${property_api_run_lender_txs_url}`,
      model
    );

    this.setState({
      lenderProspectingMode: LenderProspectingMode.LENDER_TXS,
      lenderProspectingLoading: false,
      lenderTransactionsData: result ? result : [],
    });

    return result !== null
  }

  addToBreadcrumbs = (breadcrumb) => {
    const {prospectingLenderBreadcrumbs} = this.state

    breadcrumb['index'] = prospectingLenderBreadcrumbs.length

    this.setState({
      prospectingLenderBreadcrumbs: [
        ...prospectingLenderBreadcrumbs,
        breadcrumb
      ]
    })
  }

  stepToBreadcrumb = async (breadcrumb) => {
    const {prospectingLenderBreadcrumbs} = this.state

    const {index, type, mode} = breadcrumb

    if (index < prospectingLenderBreadcrumbs.length-1) {
      const updatedBreadcrumbs = [...prospectingLenderBreadcrumbs];
      updatedBreadcrumbs.splice(index + 1); // This modifies newArray in place
      this.setState({
        prospectingLenderBreadcrumbs: updatedBreadcrumbs,
        lenderProspectingMode: mode
      })

      // switch (type) {
      //   case 'lender':
      //     await this.runProspectingLenderClicked(data);
      //     break;
      //   case 'borrower-name':
      //     await this.runProspectingBorrowerNameClicked(data);
      //     break;
      //   case 'borrower-address':
      //     await this.runProspectingBorrowerAddressClicked(data);
      //     break;
      // }
    }
  }

  // compsAddressHovered = (event) => {
  //   var element = `${event.target.id}`
  //   var button = document.getElementById(element)
  //   var marker = document.querySelector(`#marker${event.target.id}.mapboxgl-marker.mapboxgl-marker-anchor-center`)
  //   button.addEventListener('click', this.handleMarkerClick.bind(this, event.target.id.toString()), {once: true})
  //   button.addEventListener('click', toggleMarkerPopup.bind(this, marker), {once: true})
  //   button.addEventListener('mouseenter', toggleMarkerPopup.bind(this, marker), {once: true})
  //   button.addEventListener('mouseleave', toggleMarkerPopup.bind(this, marker), {once: true})
  // }

  handleMarkerClick = async (propertyId) => {
    //console.log("clicked")
    const {mapMode} = this.state;
    const isProspectingMode = mapMode === MapMode.PROSPECTING;
    const lot = await this.loadLotByBBL(propertyId);
    if (isProspectingMode) {
      this.setState({ selectedLot: lot, compsSecondaryLot: null }, () => {
        this.handleLotLayerSelection(lot);
      });
    } else {
      this.setState({ selectedLot: null, compsSecondaryLot: lot }, () => {
        this.handleSecondaryLotLayerSelection(lot);
      });
    }
    
    // var lot = cData.find((_) => _.propertyId.toString() === propertyId);
    // if (lot) {
    //   if (
    //     this.state.selectedLot && (lot.propertyId.toString() === this.state.selectedLot.propertyId.toString() || (this.state.compsSecondaryLot && lot.propertyId.toString() === this.state.compsSecondaryLot.propertyId.toString()) )
    //   ) {
    //     return;
    //   }
      
    // }
  };

  scrollToSection = (id) => {
    if (!this.state.selectedLot) {
      return;
    }
    var elmnt = document.getElementById(id);
    if (elmnt != null) {
      elmnt.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    } else {
      try {
        elmnt = document.getElementById(`${this.state.selectedLot.propertyId}`);
        if (elmnt !== null) {
          elmnt.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      } catch (e) {
        console.log("Scrolling error:", e);
      }
    }
  };

  handleHoverMarker = (propertyId) => {
    if (this.state.compsVisible) {
      this.setState({ compsHoverMarker: propertyId });
      var id = `${propertyId}`;
      this.scrollToSection(id);
    }
  };

  handleUpdateLotUnits = async (units) => {
    const { selectedLot } = this.state;
    if (selectedLot) {
      let tmp = selectedLot.uResidentialUnits;

      selectedLot.uResidentialUnits = units;
      this.setState({ selectedLot });

      let result = await makePutRequest(
        `${property_api_update_unitsres_url}?propertyId=${selectedLot.propertyId}`,
        units
      );

      if (!result) {
        selectedLot.uResidentialUnits = tmp;
      } else {
        selectedLot.uTotalUnits = result.unitsTotal;
        selectedLot.uResidentialUnits = result.unitsRes;
        selectedLot.unitCode = result.unitCode;
      }
      this.setState({ selectedLot });
    }
  };

  handleUpdateLotBldgArea = async (bldgArea) => {
    const { selectedLot } = this.state;
    if (selectedLot) {
      let tmp = selectedLot.uBldgArea;

      selectedLot.uBldgArea = bldgArea;
      this.setState({ selectedLot });

      let url = property_api_update_bldgarea_url;

      let result = await makePutRequest(
        `${url}?propertyId=${selectedLot.propertyId}`,
        bldgArea
      );

      if (!result) {
        selectedLot.uBldgArea = tmp;
        this.setState({ selectedLot });
      }
    }
  };

  handleUpdateLotProxcode = async (proxCode) => {
    const { selectedLot } = this.state;
    if (selectedLot) {
      let tmp = selectedLot.uProxCode;

      selectedLot.uProxCode = proxCode;
      this.setState({ selectedLot });

      let result = await makePutRequest(
        `${property_api_update_proxcode_url}?propertyId=${selectedLot.propertyId}`,
        proxCode
      );

      if (!result) {
        selectedLot.uProxCode = tmp;
        this.setState({ selectedLot });
      }
    }
  };

  handleUpdateLotConstruction = async (construction) => {
    const { selectedLot } = this.state;
    if (selectedLot) {
      let tmp = selectedLot.construction;

      selectedLot.construction = construction;
      this.setState({ selectedLot });

      let result = await makePutRequest(
        `${property_api_update_construction_url}?propertyId=${selectedLot.propertyId}`,
        construction
      );

      if (!result) {
        selectedLot.construction = tmp;
      } else {
        selectedLot.constructionCode = result.constructionCode;
      }

      this.setState({ selectedLot });
    }
  };

  onProspectingSearch = (prospectingType, filters, dataset, regionMode) => {
    if (prospectingType === 'prospects') {
      this.onProspectSearch(filters, dataset, regionMode)
    } else if (prospectingType === 'lenders') {
      this.onLendersProspectSearch(filters, dataset, regionMode)
    }
  }

  onProspectSearch = async (filters, dataset, regionMode) => {
    const { containerClasses } = this.state;
    if (regionMode === 'address') {
      filters.city = ''
      filters.state = ''
    } else if (regionMode === 'region') {
      filters.address = {}
    }
    var model = {
      dataset,
      searchFilter: filters
    };

    if (polygonBounds) {
      model.coordinates = polygonBounds;
    }
    addToArray(containerClasses, "prospecting-visible");
    this.setState({
      prospectingVisible: true,
      prospectingLoading: true,
      mapMode: MapMode.PROSPECTING,
      lenderProspectingMode: LenderProspectingMode.NONE,
      containerClasses,
    });
    let result = await makePostRequest(
      `${property_api_run_prospects_url}`,
      model
    );

    this.setState({
      prospectingLoading: false,
      prospectingData: result ? result : [],
    });
  };

  onLendersProspectSearch = async (filters, dataset, regionMode) => {
    const { containerClasses } = this.state;
    if (regionMode === 'address') {
      filters.city = ''
      filters.state = ''
    } else if (regionMode === 'region') {
      filters.address = {}
    }
    var model = {
      dataset,
      searchFilter: filters
    };

    if (polygonBounds) {
      model.coordinates = polygonBounds;
    }
    addToArray(containerClasses, "prospecting-visible");
    this.setState({
      prospectingVisible: true,
      lenderProspectingLoading: true,
      mapMode: MapMode.PROSPECTING,
      lenderProspectingMode: LenderProspectingMode.LENDER_SUMMARY,
      prospectingLenderBreadcrumbs: [],
      containerClasses,
    });
    
    var success = await this.runLendersProspectSearch(model);

    if (success) {
      cachedLendersProspectFilter = model;
      this.addToBreadcrumbs({
        type: 'lenders',
        label: 'Lenders',
        mode: LenderProspectingMode.LENDER_SUMMARY
      })
    }
  };

  runLendersProspectSearch = async (model) => {
    this.setState({
      lenderProspectingLoading: true,
    });
    
    let result = await makePostRequest(
      `${property_api_run_lender_summary_url}`,
      model
    );

    this.setState({
      lenderProspectingLoading: false,
      lenderSummaryData: result ? result : [],
    });

    return result
  }

  handleFetchDocumentConnections = async (documentId) => {
    var result = await makeGetRequest(
      `${property_api_get_document_connections_url}?documentId=${documentId}`,
      true
    );

    if (result) {
      const r_level = result.level;
      if (r_level === 1) {
        // Ok
        const { selectedLot } = this.state;
        if (selectedLot) {
          selectedLot.documentConnections = result.result;
          this.setState({ selectedLot });
        }
      } else if (r_level === 3) {
        // Info
        const r_message = result.message;
        alert(r_message);
      } else {
        // Failed?
        const r_message = result.message;
        alert(r_message);
      }
    }
  };

  handleCompsDateUpdate = (newDate) => {
    this.setState({ compsDate: newDate });
  };

  onFetchNarrative = async () => {
    const {selectedLot} = this.state
    if (selectedLot) {
      var result = await makeGetRequest(
        `${property_api_get_ai_narrative_url}?id=${selectedLot.propertyIdw}&ds=${selectedLot.type}`
      );

      if (result) {
        selectedLot.narrative = result
        this.setState({selectedLot})
      }
    }
  }

  desktopView = () => {
    const {
      mapMode,
      lenderProspectingMode,
      visible,
      compsSidebarVisible,
      selectedLot,
      hoverLot,
      compsPrimaryLot,
      combinedCompData,
      compsData,
      compsLoading,
      compsVisible,
      compsTableMode,
      filterVisible,
      containerClasses,
      selectedCompDetails,
      salesChart,
      radiusChart,
      tdsChart,
      prospectingData,
      prospectingFilterVisible,
      prospectingVisible,
      prospectingLoading,
      lenderProspectingLoading,
      lenderSummaryData,
      lenderTransactionsData,
      borrowerTransactionsData,
      borrowerSummaryData,
      compsBucket,
      compType,
      rentCompBedroomMode,
      compsDate,
      compsVersion,
      historicalChartData,
      prospectingLenderBreadcrumbs
    } = this.state;

    const isCompsMode = mapMode === MapMode.COMPS;
    const isCombinedCompsMode = mapMode === MapMode.COMBINEDCOMPS;
    const isProspectingMode = mapMode === MapMode.PROSPECTING;

    const isNotLenderProspecting = lenderProspectingMode === LenderProspectingMode.NONE
    const isLenderSummaryMode = lenderProspectingMode === LenderProspectingMode.LENDER_SUMMARY
    const isLenderTxsMode = lenderProspectingMode === LenderProspectingMode.LENDER_TXS
    const isBorrowerTxsMode = lenderProspectingMode === LenderProspectingMode.BORROWER_TXS
    const isBorrowerSummaryMode = lenderProspectingMode === LenderProspectingMode.BORROWER_SUMMARY

    return (
      <div className={`home-container ${containerClasses.join(" ")}`}>
        <Sidebar.Pushable as={Segment} style={{ overflow: "hidden" }}>
          {isProspectingMode ? (
            <Sidebar
              className={`prospecting-table-container ${
                isProspectingMode ? "prospecting-mode" : ""
              }`}
              as={Segment}
              animation="overlay"
              direction="bottom"
              visible={prospectingVisible}
            >
              <Label
                className="panel-closer up"
                basic
                icon={`long arrow alternate ${
                  prospectingVisible ? "down" : "up"
                }`}
                floating
                onClick={this.handleProspectingTable.bind(this)}
              ></Label>

              {!isNotLenderProspecting ? (
                <div className="breadcrumbs-container">
                  {
                    <>
                    {!prospectingLenderBreadcrumbs || prospectingLenderBreadcrumbs.length === 0 ? (
                      <ArrowTriangle direction="right" />
                    ) : <>
                     {prospectingLenderBreadcrumbs?.map((x, index) => <div className="breadcrumbs-cell" key={index}>
                      <ArrowTriangle direction="right" />
                      <Label onClick={() => this.stepToBreadcrumb(x)}>{x.label}</Label>
                    </div>
                    )}
                    </>}
                    </>
                  }
                </div>
              ) : null}

              {isNotLenderProspecting ? (
                <ProspectingTable
                  data={prospectingData}
                  selectedLot={selectedLot}
                  //hoverMarker={compsHoverMarker}
                  loading={prospectingLoading}
                  handleAddressClicked={this.prospectingAddressClicked.bind(this)}
                  //handleAddressHovered={this.compsAddressHovered.bind(this)}
                />
              ) : isLenderSummaryMode ? (
                <LenderSummaryTable
                  data={lenderSummaryData}
                  loading={lenderProspectingLoading}
                  handleLenderClicked={this.prospectingLenderClicked.bind(this)}
                  />
              ) : isLenderTxsMode ? (
                <LenderTransactionsTable
                  data={lenderTransactionsData}
                  loading={lenderProspectingLoading}
                  selectedLot={selectedLot}
                  handleAddressClicked={this.prospectingAddressClicked.bind(this)}
                  handleBorrowerNameClicked={this.prospectingBorrowerNameClicked.bind(this)}
                  handleBorrowerAddressClicked={this.prospectingBorrowerAddressClicked.bind(this)}
                />
              ) : isBorrowerTxsMode ? (
                <BorrowerTransactionsTable
                  data={borrowerTransactionsData}
                  loading={lenderProspectingLoading}
                  selectedLot={selectedLot}
                  handleAddressClicked={this.prospectingAddressClicked.bind(this)}
                />
              ) : isBorrowerSummaryMode ? (
                <UniqueBorrowerTable 
                data={borrowerSummaryData}
                loading={lenderProspectingLoading}
                handleBorrowerNameClicked={this.prospectingBorrowerNameClicked.bind(this)}
                />
              ) : null}

            </Sidebar>
          ) : null}

          {isCompsMode || isCombinedCompsMode ? (
            <Sidebar
              className={`comps-table-container ${
                isCompsMode || isCombinedCompsMode ? "comps-mode" : ""
              }`}
              as={Segment}
              animation="overlay"
              direction="bottom"
              visible={compsVisible}
            >
              <Label
                className="panel-closer up"
                basic
                icon={`long arrow alternate ${compsVisible ? "down" : "up"}`}
                floating
                onClick={this.handleCompsTable.bind(this)}
              ></Label>
              {/* <Radio toggle checked={!compsTableMode} onChange={this.handleCompsTableSwitch.bind(this)} /> */}
              {isCompsMode ? (
                <>
                  <Button.Group size="small">
                    <Button
                      positive={
                        compsBucket === CompBucketOption.HIGH
                      }
                      onClick={this.handleCompsTableSwitch.bind(
                        this,
                        CompBucketOption.HIGH
                      )}
                    >
                      High
                    </Button>
                    <Button
                      positive={
                        compsBucket ===
                        CompBucketOption.MIDDLEHIGH
                      }
                      onClick={this.handleCompsTableSwitch.bind(
                        this,
                        CompBucketOption.MIDDLEHIGH
                      )}
                    >
                      Mid-High
                    </Button>
                    <Button
                      positive={
                        compsBucket === CompBucketOption.MIDDLE
                      }
                      onClick={this.handleCompsTableSwitch.bind(
                        this,
                        CompBucketOption.MIDDLE
                      )}
                    >
                      Middle
                    </Button>
                    <Button
                      positive={
                        compsBucket ===
                        CompBucketOption.MIDDLELOW
                      }
                      onClick={this.handleCompsTableSwitch.bind(
                        this,
                        CompBucketOption.MIDDLELOW
                      )}
                    >
                      Mid-Low
                    </Button>
                    <Button
                      positive={
                        compsBucket === CompBucketOption.LOW
                      }
                      onClick={this.handleCompsTableSwitch.bind(
                        this,
                        CompBucketOption.LOW
                      )}
                    >
                      Low
                    </Button>
                  </Button.Group>
                  {compType === CompTypeOption.RENT &&
                  compsPrimaryLot &&
                  Lot.isPluto(compsPrimaryLot) && compsPrimaryLot.bedrooms ? (
                    <Button.Group floated="right" size="small">
                      {compsPrimaryLot.bedrooms.filter(onlyUnique).map((_) => {
                        return (
                          <Button
                            key={_}
                            positive={rentCompBedroomMode === _}
                            onClick={this.handleCompsRentalSwitch.bind(
                              this,
                              _
                            )}
                          >
                            {_} Br
                          </Button>
                        );
                      })}
                    </Button.Group>
                  ) : null}

                  <Button.Group floated="right" size="small">
                    <Button
                      positive={
                        compType === CompTypeOption.DEED
                      }
                      onClick={this.runComps.bind(
                        this,
                        CompTypeOption.DEED,
                        null
                      )}
                    >
                      Sales
                    </Button>
                    <Button
                      positive={
                        compType === CompTypeOption.MORTGAGE
                      }
                      onClick={this.runComps.bind(
                        this,
                        CompTypeOption.MORTGAGE,
                        null
                      )}
                    >
                      Financing
                    </Button>
                    <Button
                      positive={
                        compType === CompTypeOption.LISTING
                      }
                      onClick={this.runComps.bind(
                        this,
                        CompTypeOption.LISTING,
                        null
                      )}
                    >
                      Listings
                    </Button>
                    <Button
                      positive={
                        compType === CompTypeOption.RENT
                      }
                      onClick={this.runComps.bind(
                        this,
                        CompTypeOption.RENT,
                        Lot.isPluto(selectedLot) && selectedLot?.bedrooms
                          ? selectedLot?.bedrooms[0]
                          : selectedLot?.bedrooms
                      )}
                    >
                      Rentals
                    </Button>
                    {Lot.isFA(selectedLot) ? (
                      <Button
                      positive={
                        compType === CompTypeOption.NEIGHBORHOOD
                      }
                      onClick={this.runCityComps.bind(
                        this,
                        CompTypeOption.NEIGHBORHOOD,
                        null
                      )}
                    >
                      City
                    </Button>
                    ): null } 
                    {Lot.isFA(selectedLot) ? (
                      <Button
                      positive={
                        compType === CompTypeOption.NEIGHBORHOOD
                      }
                      onClick={this.runCombinedComps.bind(
                        this
                      )}
                    >
                      Combined
                    </Button>
                    ): null } 
                    <Button
                      onClick={(e) => {downloadCSV(compsData?.comps, 'comps', csvColumns)}}
                    >
                      Export
                    </Button>                    
                  </Button.Group>
                </>
              ) : null}

              {isCompsMode ? (
                <CompsTableV3
                  bucket={compsBucket}
                  type={compType}
                  data={compsData?.comps}
                  selectedLot={compsPrimaryLot}
                  loading={compsLoading}
                  handleAddressClicked={this.compsAddressClicked.bind(this)}
                />
              ) : isCombinedCompsMode ? (
                <CompsTableV3
                  bucket={compsBucket}
                  type={compType}
                  data={combinedCompData?.cityComps}
                  selectedLot={compsPrimaryLot}
                  loading={compsLoading}
                  handleAddressClicked={this.compsAddressClicked.bind(this)}
                />
              ) : null}
            </Sidebar>
          ) : null}

          {historicalChartData && (
            <Segment className="historical-chart-container">
              <HistoryChart historicalData={historicalChartData} />
            </Segment>
          )}

          <HorizontalSidebar
            animation="overlay"
            direction="right"
            width="very wide"
            visible={visible}
            selectedLot={selectedLot}
            handlePanelOpen={this.handleRightPanelOpen.bind(this)}
            handlePanelClose={this.handleRightPanelClose.bind(this)}
            handleAnimationChange={this.handleSidePanel.bind(this)}
            handleNotesChange={this.handleNotesChange.bind(this)}
            handleWideStreetChange={this.handleWideStreetChange.bind(this)}
            handleCombinedComps={this.handleCombinedComps.bind(this)}
            handleCombinedForcedComps={this.handleCombinedForcedComps.bind(this)}
            handleComps={this.handleCompsSearch.bind(this)}
            //handleHistoricalComps={this.handleHistoricalComps.bind(this)}
            handleComps2={this.handleCompsVersion.bind(this, 8)}
            handleComps3={this.handleCompsVersion.bind(this, 6)}
            handleComps4={this.handleCompsVersion.bind(this, 7)}
            handleComps5={this.handleCompsVersion.bind(this, 5)}
            handleComps6={this.handleCompsVersion.bind(this, 9)}
            handleFetchDocumentConnections={this.handleFetchDocumentConnections.bind(
              this
            )}
            handleAddToWatchlist={this.handleAddToWatchlist.bind(this)}
            handleAddToOwnership={this.handleAddToOwnership.bind(this)}
            handleAddToContract={this.handleAddToContract.bind(this)}
            combinedCompsActive={isCombinedCompsMode}
            compsActive={isCompsMode && (!compsVersion || compsVersion < 2)}
            comps2Active={compsVersion === 8}
            comps3Active={compsVersion === 6}
            comps4Active={compsVersion === 7}
            comps5Active={compsVersion === 5}
            comps6Active={compsVersion === 9}
            lotUpdateHandlers={[
              this.handleUpdateLotUnits.bind(this),
              this.handleUpdateLotBldgArea.bind(this),
              this.handleUpdateLotProxcode.bind(this),
              this.handleUpdateLotConstruction.bind(this),
            ]}
            compsDate={compsDate}
            handleCompsDateUpdate={this.handleCompsDateUpdate}
            onFetchNarrative={this.onFetchNarrative.bind(this)}
          />

          <Sidebar
            className={`comps-filter-container ${
              isCompsMode ? "comps-mode" : ""
            }`}
            as={Segment}
            animation="push"
            direction="left"
            width="wide"
            visible={filterVisible}
            onShow={this.handleFilterPanelOpen.bind(this)}
            onHide={this.handleFilterPanelClose.bind(this)}
          >
            <Label
              className="panel-closer right"
              basic
              icon={`long arrow alternate ${filterVisible ? "left" : "right"}`}
              floating
              onClick={this.handleFilterPanel.bind(this)}
            ></Label>
            <CompsFilter
            //handleCompsSearch={this.applyFilters.bind(this)}
            //applyFilters={this.applyFilters.bind(this)}
            />
          </Sidebar>

          <Sidebar
            className={`prospecting-filter-container ${
              isCompsMode ? "comps-mode" : ""
            }`}
            as={Segment}
            animation="push"
            direction="left"
            width="wide"
            visible={prospectingFilterVisible}
            onShow={this.handleFilterPanelOpen.bind(this)}
            onHide={this.handleFilterPanelClose.bind(this)}
          >
            <Label
              className="panel-closer right"
              basic
              icon={`long arrow alternate ${
                prospectingFilterVisible ? "left" : "right"
              }`}
              floating
              onClick={this.handleProspectingFilterPanel.bind(this)}
            ></Label>
            <ProspectingFilterPanel
              onProspectSearch={this.onProspectingSearch.bind(this)}
            />
          </Sidebar>

          <>
            <Sidebar.Pusher>
              <SearchPanel
                lng={this.state.lng}
                lat={this.state.lat}
                zoom={this.state.zoom}
                text={this.state.text}
                handleResultSelect={this.handleResultSelect}
                handleSearchChange={this.handleSearchChange}
                searchState={this.state.searchState}
                isLoading={this.state.isLoading}
              />
              <div ref={this.mapContainerRef} className="mapContainer" />

              {hoverLot ? (
                <section
                  className={`address-display-container${
                    visible ? " info-visible" : ""
                  }`}
                >
                  <AddressDisplayPanel lot={hoverLot} />
                </section>
              ) : null}
            </Sidebar.Pusher>

            {selectedCompDetails ? (
              <CompsPopup selectedCompDetails={selectedCompDetails} />
            ) : null}

            {isCompsMode && selectedLot ? (
              <>
                <CompsSidebar
                  animation="push"
                  direction="right"
                  width="very wide"
                  compType={compsTableMode}
                  visible={compsSidebarVisible}
                  compsBuckets={compsData?.buckets?.finalBuckets}
                  compsStats={compsData?.stats}
                  //bestBucketSet={compsData?.bestBucketSet}
                  //neighborhoodAverages={compsData?.neighborhoodAverages}
                  compsLoading={compsLoading}
                  handleAnimationChange={this.handleCompsSidebarPanel.bind(
                    this
                  )}
                  selectedLot={selectedLot}
                  salesChart={salesChart}
                  radiusChart={radiusChart}
                  tdsChart={tdsChart}
                />
                <CompsExcludedSidebar
                  animation="push"
                  direction="right"
                  width="very wide"
                  topComps={compsData?.comps?.filter(_ => _.isRanked)}
                  compsStats={compsData?.stats}
                  aiBucket={compsData?.buckets?.aiBucket}
                  simpleBuckets={compsData?.buckets?.simpleBuckets}
                  erSimpleBuckets={compsData?.buckets?.erSimpleBuckets}
                  topZBuckets={compsData?.buckets?.topZBuckets}
                  composyteBuckets={compsData?.buckets?.composyteBuckets}
                  bestBuckets={compsData?.buckets?.bestBuckets}
                  finalCombinedBuckets={compsData?.buckets?.finalCombinedBuckets}
                  //compsCalc={compsData?.calc}
                  compsLoading={compsLoading}
                  tdsChart={tdsChart}
                  compAnalytics={compsData?.analytics}
                  //bestBuckets={compsData?.bestBuckets}
                />
              </>
            ) : isCombinedCompsMode && selectedLot ? (
                <CombinedCompsSidebar
                    animation="push"
                    direction="right"
                    width="very wide"
                    compType={compsTableMode}
                    visible={compsSidebarVisible}
                    compsData={combinedCompData}
                    compsLoading={compsLoading}
                    handleAnimationChange={this.handleCompsSidebarPanel.bind(
                      this
                    )}
                    selectedLot={selectedLot}
                  />
            ) : null}
          </>
        </Sidebar.Pushable>
      </div>
    );
  };

  mobileView = () => {
    const {
      mapMode,
      selectedLot,
      compsPrimaryLot,
      compsData,
      compsLoading,
      compsBucket,
      compType,
      rentCompBedroomMode,
      compsDate,
      tdsChart,
      compsVersion
    } = this.state;

    const isCompsMode = mapMode === MapMode.COMPS;
    const isCombinedCompsMode = mapMode === MapMode.COMBINEDCOMPS;

    //const {calc, neighborhoodAverages} = compsData || null
    const {buckets: compsBuckets, stats: compsStats} = compsData || {}

    const {finalBuckets, composyteBuckets} = compsBuckets || {}
    // const {combinedBuckets} = calc?.finalBuckets || {}

    // const {
    //   composyteSectorBucket,
    //   composyteLTDSBucket,
    //   composyteDistanceBucket,
    //   composyteHighestBucket,
    //   composyteLowestBucket
    // } = calc?.analytics || {};

    // const composyteBuckets = [
    //   composyteDistanceBucket,
    //   composyteLTDSBucket,
    //   composyteSectorBucket,
    //   composyteHighestBucket,
    //   composyteLowestBucket
    // ];

    return (
      <div className={`home-container`}>
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              <SearchPanel
                lng={this.state.lng}
                lat={this.state.lat}
                zoom={this.state.zoom}
                text={this.state.text}
                handleResultSelect={this.handleResultSelect}
                handleSearchChange={this.handleSearchChange}
                searchState={this.state.searchState}
              />
              <div ref={this.mapContainerRef} className="mapContainer mobile" />
            </Grid.Column>
          </Grid.Row>
          {isCompsMode && finalBuckets && (
            <>
              <Grid.Row style={{ paddingBottom: "0" }}>
                <CompsCalcPanel
                  selectedLot={selectedLot}
                  compsBuckets={finalBuckets}
                  compsStats={compsStats}
                  //calc={calc}
                  //bestBucketSet={bestBucketSet}
                  //neighborhoodAverages={neighborhoodAverages}
                  loading={compsLoading}
                />
                {tdsChart && <Segment basic className="no-padding-v" style={{width: '100%', maxHeight: '600px'}}>
                              <LTDSChart chartData={tdsChart} scatterSize={5} colorKey={'finalColor'} />
                            </Segment>
                }
                {/* <CompsClusterBucketsSummaryPanel
                  buckets={combinedBuckets}
                  tdsChart={tdsChart}
                  tdsColor={'tertiaryColor'}
                />
              <Divider/>*/}
                <CompsClusterBucketsSummaryPanel
                  buckets={composyteBuckets}
                  showTitles={true}
                />
              </Grid.Row>
              {selectedLot && selectedLot.zestimate && (
                <ZillowEstimatesPanel lot={selectedLot} />
              )}
              <Grid.Row textAlign="center">
                <Grid.Column>
                  <Button.Group size="mini">
                    <Button
                      positive={
                        compsBucket === CompBucketOption.HIGH
                      }
                      onClick={this.handleCompsTableSwitch.bind(
                        this,
                        CompBucketOption.HIGH
                      )}
                    >
                      High
                    </Button>
                    <Button
                      positive={
                        compsBucket ===
                        CompBucketOption.MIDDLEHIGH
                      }
                      onClick={this.handleCompsTableSwitch.bind(
                        this,
                        CompBucketOption.MIDDLEHIGH
                      )}
                    >
                      Mid-High
                    </Button>
                    <Button
                      positive={
                        compsBucket === CompBucketOption.MIDDLE
                      }
                      onClick={this.handleCompsTableSwitch.bind(
                        this,
                        CompBucketOption.MIDDLE
                      )}
                    >
                      Middle
                    </Button>
                    <Button
                      positive={
                        compsBucket ===
                        CompBucketOption.MIDDLELOW
                      }
                      onClick={this.handleCompsTableSwitch.bind(
                        this,
                        CompBucketOption.MIDDLELOW
                      )}
                    >
                      Mid-Low
                    </Button>
                    <Button
                      positive={
                        compsBucket === CompBucketOption.LOW
                      }
                      onClick={this.handleCompsTableSwitch.bind(
                        this,
                        CompBucketOption.LOW
                      )}
                    >
                      Low
                    </Button>
                  </Button.Group>
                </Grid.Column>
                <Grid.Column style={{ marginTop: "0.5em" }}>
                  {compType === CompTypeOption.RENT &&
                  compsPrimaryLot &&
                  Lot.isPluto(compsPrimaryLot) && compsPrimaryLot.bedrooms ? (
                    <Button.Group floated="right" size="mini">
                      {compsPrimaryLot.bedrooms.filter(onlyUnique).map((_) => {
                        return (
                          <Button
                            key={_}
                            positive={rentCompBedroomMode === _}
                            onClick={this.handleCompsRentalSwitch.bind(
                              this,
                              _
                            )}
                          >
                            {_} Br
                          </Button>
                        );
                      })}
                    </Button.Group>
                  ) : null}

                  <Button.Group size="mini">
                    <Button
                      positive={
                        compType === CompTypeOption.DEED
                      }
                      onClick={this.runComps.bind(
                        this,
                        CompTypeOption.DEED,
                        null
                      )}
                    >
                      Sales
                    </Button>
                    <Button
                      positive={
                        compType === CompTypeOption.MORTGAGE
                      }
                      onClick={this.runComps.bind(
                        this,
                        CompTypeOption.MORTGAGE,
                        null
                      )}
                    >
                      Financing
                    </Button>
                    <Button
                      positive={
                        compType === CompTypeOption.LISTING
                      }
                      onClick={this.runComps.bind(
                        this,
                        CompTypeOption.LISTING,
                        null
                      )}
                    >
                      Listings
                    </Button>
                    <Button
                      positive={
                        compType === CompTypeOption.RENT
                      }
                      onClick={this.runComps.bind(
                        this,
                        CompTypeOption.RENT,
                        Lot.isPluto(selectedLot) && selectedLot?.bedrooms
                          ? selectedLot?.bedrooms[0]
                          : selectedLot?.bedrooms
                      )}
                    >
                      Rentals
                    </Button>
                    {Lot.isFA(selectedLot) ? (
                      <Button
                      positive={
                        compType === CompTypeOption.NEIGHBORHOOD
                      }
                      onClick={this.runCityComps.bind(
                        this,
                        CompTypeOption.NEIGHBORHOOD,
                        null
                      )}
                    >
                      City
                    </Button>
                    ): null } 
                  </Button.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <CompsTableV3
                    bucket={compsBucket}
                    type={compType}
                    data={compsData?.comps}
                    selectedLot={compsPrimaryLot}
                    loading={compsLoading}
                    handleAddressClicked={this.compsAddressClicked.bind(this)}
                  />
                </Grid.Column>
              </Grid.Row>
            </>
          )}
          {selectedLot && (
            <Grid.Row>
              <Grid.Column>
                <FullLotInformation
                  isMobile={true}
                  selectedLot={selectedLot}
                  handleNotesChange={this.handleNotesChange.bind(this)}
                  handleWideStreetChange={this.handleWideStreetChange.bind(
                    this
                  )}
                  handleComps={this.handleCompsSearch.bind(
                    this
                  )}
                  handleComps2={this.handleCompsVersion.bind(this, 8)}
                  handleComps3={this.handleCompsVersion.bind(this, 6)}
                  handleComps4={this.handleCompsVersion.bind(this, 7)}
                  handleComps5={this.handleCompsVersion.bind(this, 5)}
                  handleComps6={this.handleCompsVersion.bind(this, 9)}
                  //handleHistoricalComps={this.handleHistoricalComps.bind(this)}
                  compsActive={isCompsMode && (!compsVersion || compsVersion < 2)}
                  comps2Active={compsVersion === 8}
                  comps3Active={compsVersion === 6}
                  comps4Active={compsVersion === 7}
                  comps5Active={compsVersion === 5}
                  comps6Active={compsVersion === 9}
                  lotUpdateHandlers={[
                    this.handleUpdateLotUnits.bind(this),
                    this.handleUpdateLotBldgArea.bind(this),
                    this.handleUpdateLotProxcode.bind(this),
                    this.handleUpdateLotConstruction.bind(this),
                  ]}
                  compsDate={compsDate}
                  handleCompsDateUpdate={this.handleCompsDateUpdate}
                  onFetchNarrative={this.onFetchNarrative.bind(this)}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </div>
    );
  };

  render() {
    const { isMobile, loadingHistory } = this.state;
    return (
      <div className="home">
        {!isMobile ? <>{this.desktopView()}</> : <>{this.mobileView()}</>}
        {loadingHistory ? <Dimmer active>
                            <Loader />
                          </Dimmer> : null}
      </div>
    );
  }
}
