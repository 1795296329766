import React, { Component } from 'react';
import Lot from "../Tax-Lot.js";
import memoize from 'memoize-one';
import Datatable from 'react-data-table-component'
import _ from 'lodash'

import Spinner from "./Loader"

import {reorderData} from './Comps'

import {acris_document_viewer_url} from "../utility/Config"

import {
    Label,
    Popup,
} from 'semantic-ui-react'

import { downloadCSV, formatDate, formatNumber, isEmpty } from '../utility/Helper.js';
import { CompBucketOption, CompTypeOption } from '../utility/Global.js';
//import { FilterComponent } from '../utility/TableHelper.js';
import { GridForPopup } from './extras/Misc.js';
//import { LotStatsPanel } from './lots/LotInformationPanel.jsx';
import SignalBars from './extras/SignalBars.jsx';

function hashString(str) {
  let hash = 5381;
  for (let i = 0; i < str.length; i++) {
      hash = (hash * 33) ^ str.charCodeAt(i);
  }
  return hash >>> 0; // Convert to an unsigned 32-bit integer
}


function bucketShortName(bucket) {
  if (bucket === CompBucketOption.MIDDLELOW) {
    return 'ML'
  } else if (bucket === CompBucketOption.MIDDLEHIGH) {
    return 'MH'
  } else {
    return bucket[0]
  }
}

function getDisabledReasons(lot) {
  if (!lot.isDisabled) return null
  var str = ""

  if (lot.isOutOfPriceBounds) {
    str += "Out of Price Bounds\n"
  }
  if (lot.isOutOfFinalBounds) {
    str += "Out of Final Sqft Bounds\n"
  }
  if (lot.isOutOfTotalBounds) {
    str += "Out of Total Sqft Bounds\n"
  }
  if (lot.isOutOfLotBounds) {
    str += "Out of Lot Sqft Bounds\n"
  }
  if (lot.isInconsistentPricing) {
    str += "Invalid Flip\n"
  }
  if (lot.sameNameParty) {
    str += "Same Name Party\n"
  }
  if (lot.bankNameParty) {
    str += "Bank Name Party\n"
  }
  if (lot.isInvalidStyle) {
    str += "Invalid Style\n"
  }
  if (lot.sameBuyer) {
    str += "Same Buyer\n"
  }
  if (lot.isInvalidGarage) {
    str += "Garage"
  }
  if (lot.isInvalidBasement) {
    str += "Basement"
  }
  return str
}

const csvColumns = {
  bucket: 'Bucket',
  address: 'Address',
  composyteLikenessScore: 'Likeness',
  composyteTimeDistanceScore: 'TDS',
  ltds: 'LTDS',
  bldgArea: 'BLDG Area',
  documentAmount: 'Purchase Amount',
  documentDate: 'Closing Date',
  pricePerSqft: 'PSF'
}

const csvColumnsV3 = {
  bucket: 'Bucket',
  address: 'Address',
  composyteLikenessScore: 'Likeness',
  composyteTimeDistanceScore: 'TD',
  composyteSizeScore: 'Size',
  ltds: 'LTDS',
  zip: 'Zip',
  distance: 'Distance',
  documentAmount: 'Purchase Amount',
  documentDate: 'Closing Date',
  daysFromSale: 'Days From Sale',
  bldgArea: 'BLDG Area',
  pricePerSqft: 'PSF',
  sellerNames: 'Sellers',
  buyerNames: 'Buyers',
  heldString: 'Time Held',
  stories: 'Stories',
  unitstotal: 'Total Units',
  zoning: 'Zoning',
  lotArea: 'Lot Sqft',
  yearBuilt: 'Year Built',
  neighborhood: 'Neighborhood'
}


const paginationComponentOptions = {
  rowsPerPageText: 'Lots per page',
  selectAllRowsItem: false
};

const customStyles = {
  // subHeader: {
  //     style: {
  //         padding: '0'
  //     }
  // },
  // headRow: {
  //   style: {
  //       height: '48px',
  //       backgroundColor: 'lightblue'
  //   }
  // },
  headCells: {
    style: {
      //color: '#202124',
      fontSize: '11px',
      fontWeight: 'bolder',
      //textTransform: 'uppercase'
    },
  },
  rows: {
    style: {
        //fontSize: '11px',
        //border: '1px solid grey',
    }
  },
  cells: {
    style: {
      fontSize: '11px',
      paddingLeft: '8px !important',
      paddingRight: '8px !important',
    },
  },
  pagination: {
    style: {
      border: 'none',
      maxHeight: '40px',
      minHeight: '40px'
    },
  },
};

const uniqueBorrowerColumns = memoize((handleAction) => [
  {
    name: 'Borrower',
    selector: 'borrowerName',
    sortable: true,
    minWidth: '350px !important',
    maxWidth: '600px',
    allowOverflow: true,
    button: false,
    cell: row => <span style={{cursor: 'pointer'}} id={hashString(row.borrowerName)} onClick={() => handleAction(row.borrowerName)}>{row.borrowerName}</span>
  }
])

const lenderSummaryColumns = memoize((type, summaryId, handleAction) => [
  {
    name: 'Lender',
    selector: 'lenderName',
    sortable: true,
    minWidth: '350px !important',
    maxWidth: '600px',
    allowOverflow: true,
    button: false,
    cell: row => <span id={row.entityId ?? row.individualId ?? hashString(row.lenderName)} style={{cursor: 'pointer'}} onClick={() => handleAction(type, summaryId, row)}>{row.lenderName}</span>
  },
  {
    name: 'Units',
    selector: 'totalLoans',
    sortable: true,
    format: row => formatNumber((row.totalLoans))
  },
  {
    name: 'Volume',
    selector: 'totalAmount',
    sortable: true,
    format: row => `$${formatNumber((row.totalAmount))}`
  }
])

const lenderTransactionsColumns = memoize((type, handleAction, handleBorrowerNameClicked, handleBorrowerAddressClicked) => [
 
  {
    name: 'Borrower',
    selector: 'borrower1',
    sortable: true,
    minWidth: '250px !important',
    cell : row => <div>
      {row.borrower1 && <span id={row.transactionId} style={{cursor: 'pointer'}} onClick={() => {handleBorrowerNameClicked(type, row.borrower1)}}>{row.borrower1}</span>}
    </div>
  },
  {
    name: 'Loan Date',
    selector: 'loanDate',
    sortable: true,
    center: true,
    //width: '120px !important',
    format: row => row.loanDate ? formatDate(row.loanDate) : '-'
  },
  {
    name: 'Loan Amount',
    selector: 'loanAmount',
    sortable: true,
    center: true,
    //width: '125px !important',
    cell: row => (
          <div className='prospecting-lender-name'>
            <span>
              {row.loanAmount ? (
                <>
                  {type === 'pluto' ? (
                    <a
                      style={{ color: 'inherit' }}
                      href={acris_document_viewer_url + row.loanId}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ${formatNumber(row.loanAmount)}
                    </a>
                  ) : (
                    <>${formatNumber(row.loanAmount)}</>
                  )}
                </>
              ) : (
                '-'
              )}
            </span>
          </div>
        )
  },
  { 
    name: 'Borrower Address',
    selector: 'buyerBorrower1HouseAddress',
    sortable: true,
    minWidth: '200px !important',
    cell: row => <div>
      {row.borrower1 && <span style={{cursor: 'pointer'}} onClick={() => {handleBorrowerAddressClicked(type, row.buyerBorrower1HouseAddress, row.buyerBorrower1HouseAddress2, row.buyerBorrower1City, row.buyerBorrower1State, row.buyerBorrower1Zip)}}>{row.buyerBorrower1HouseAddress} {row.buyerBorrower1HouseAddress2}<br/> {row.buyerBorrower1City}, {row.buyerBorrower1State} {row.buyerBorrower1Zip}</span>}
    </div>
  },
  {
    cell: row => <Label id={row.propertyId} className='prospecting-address' basic onClick={() => {handleAction(row.propertyId)}}>{row.address}</Label>,
    name: 'Address',
    selector: 'address',
    sortable: true,
    minWidth: '200px !important',
    maxWidth: '320px',
    //grow: 4,
    allowOverflow: true,
    button: false
  },
  {
    name: 'Zip',
    selector: 'zip',
    sortable: true,
    center: true
  },
  {
    name: 'Total Units',
    selector: 'unitstotal',
    sortable: true,
    center: true,
    //width: '60px !important',
    cell: row => (<span title={`Residential: ${Lot.residentialUnits(row)}\nCommercial: ${Lot.commericalUnits(row)}`}>{Lot.totalUnits(row)}</span>)
  },
  {
    name: 'Acquisition Date',
    selector: 'saleDate',
    sortable: true,
    center: true,
    //width: '120px !important',
    format: row => (row.saleDate ?  <span>{formatDate(row.saleDate)}</span> : '-')
  },
  {
    name: 'Acquisition Price',
    selector: 'saleAmount',
    sortable: true,
    center: true,
    grow: 1.5,
    //width: '125px !important',
    cell : row => (row.saleAmount ? <span>
        {type === 'pluto' ? (
            (<a style={{color:'inherit'}} href={acris_document_viewer_url + row.saleId} target="_blank" rel="noopener noreferrer">${formatNumber((row.saleAmount))}</a>)
        ) : <>${formatNumber((row.saleAmount))}</> }
    </span> : '-')
  },
  {
    name: 'Borrower',
    selector: 'borrower2',
    sortable: true,
    minWidth: '250px !important',
    cell : row => <div>
      {row.borrower2 && <span id={row.transactionId} style={{cursor: 'pointer'}} onClick={() => {handleBorrowerNameClicked(type, row.borrower2)}}>{row.borrower2}</span> }
    </div>
  },
  { 
    name: 'Borrower Address',
    selector: 'buyerBorrower2HouseAddress',
    sortable: true,
    minWidth: '200px !important',
    cell: row => <div>
      {row.borrower2 && <span style={{cursor: 'pointer'}} onClick={() => {handleBorrowerAddressClicked(type, row.buyerBorrower2HouseAddress, row.buyerBorrower2HouseAddress2, row.buyerBorrower2City, row.buyerBorrower2State, row.buyerBorrower2Zip)}}>{row.buyerBorrower2HouseAddress} {row.buyerBorrower2HouseAddress2}<br/> {row.buyerBorrower2City}, {row.buyerBorrower2State} {row.buyerBorrower2Zip}</span>}
    </div>
  },
  {
    name: 'Seller',
    selector: 'seller1',
    sortable: true,
    minWidth: '150px !important',
    cell: row => <span title={row.seller1}>{row.seller1}</span>
  },
  {
    name: 'Seller',
    selector: 'seller2',
    sortable: true,
    minWidth: '150px !important',
    cell: row => <span title={row.seller2}>{row.seller2}</span>
  }
])

const borrowerTransactionsColumns = memoize((type, handleAction) => [
 
  {
    name: 'Lender',
    selector: 'lender1',
    sortable: true,
    minWidth: '250px !important',
    cell : row => <div>
      {row.lender1 && <span id={row.transactionId} style={{cursor: 'pointer'}} /* onClick={() => {handleLenderNameClicked(row.lender1)} } */>{row.lender1}</span>}
    </div>
  },
  {
    name: 'Loan Date',
    selector: 'loan1Date',
    sortable: true,
    center: true,
    //width: '120px !important',
    format: row => row.loan1Date ? formatDate(row.loan1Date) : '-'
  },
  {
    name: 'Loan Amount',
    selector: 'loan1Amount',
    sortable: true,
    center: true,
    //width: '125px !important',
    cell: row => (
          <div className='prospecting-lender-name'>
            <span>
              {row.loanAmount ? (
                <>
                  {type === 'pluto' ? (
                    <a
                      style={{ color: 'inherit' }}
                      href={acris_document_viewer_url + row.loan1Id}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ${formatNumber(row.loan1Amount)}
                    </a>
                  ) : (
                    <>${formatNumber(row.loan1Amount)}</>
                  )}
                </>
              ) : (
                '-'
              )}
            </span>
          </div>
        )
    },
    {
      name: 'Lender',
      selector: 'lender2',
      sortable: true,
      minWidth: '250px !important',
      cell : row => <div>
        {row.lender2 && <span id={row.transactionId} style={{cursor: 'pointer'}} /* onClick={() => {handleLenderNameClicked(row.lender2)} } */>{row.lender2}</span>}
      </div>
    },
    ...[(type === 'fa' ? ({
      name: 'Loan 2 Date',
      selector: 'loan2Date',
      sortable: true,
      center: true,
      //width: '120px !important',
      format: row => row.loan2Date ? formatDate(row.loan2Date) : '-'
    },
    {
      name: 'Loan Amount',
      selector: 'loan2Amount',
      sortable: true,
      center: true,
      //width: '125px !important',
      cell: row => (
            <div className='prospecting-lender-name'>
              <span>
                {row.loanAmount ? (
                  <>
                    {type === 'pluto' ? (
                      <a
                        style={{ color: 'inherit' }}
                        href={acris_document_viewer_url + row.loan1Id}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ${formatNumber(row.loan2Amount)}
                      </a>
                    ) : (
                      <>${formatNumber(row.loan2Amount)}</>
                    )}
                  </>
                ) : (
                  '-'
                )}
              </span>
            </div>
          )
      }) : {})],
  {
    cell: row => <Label id={row.propertyId} className='prospecting-address' basic onClick={() => {handleAction(row.propertyId)}}>{row.address}</Label>,
    name: 'Address',
    selector: 'address',
    sortable: true,
    minWidth: '250px !important',
    maxWidth: '320px',
    //grow: 4,
    allowOverflow: true,
    button: false
  },
  {
    name: 'Zip',
    selector: 'zip',
    sortable: true,
    center: true
  },
  {
    name: 'Total Units',
    selector: 'unitstotal',
    sortable: true,
    center: true,
    //width: '60px !important',
    cell: row => (<span title={`Residential: ${Lot.residentialUnits(row)}\nCommercial: ${Lot.commericalUnits(row)}`}>{Lot.totalUnits(row)}</span>)
  },
  {
    name: 'Acquisition Date',
    selector: 'saleDate',
    sortable: true,
    center: true,
    //width: '120px !important',
    format: row => (row.saleDate ?  <span>{formatDate(row.saleDate)}</span> : '-')
  },
  {
    name: 'Acquisition Price',
    selector: 'saleAmount',
    sortable: true,
    center: true,
    grow: 1.5,
    //width: '125px !important',
    cell : row => (row.saleAmount ? <span>
        {type === 'pluto' ? (
            (<a style={{color:'inherit'}} href={acris_document_viewer_url + row.saleId} target="_blank" rel="noopener noreferrer">${formatNumber((row.saleAmount))}</a>)
        ) : <>${formatNumber((row.saleAmount))}</> }
    </span> : '-')
  },
  {
    name: 'Seller',
    selector: 'seller1',
    sortable: true,
    minWidth: '150px !important',
    cell: row => <span title={row.seller1}>{row.seller1}</span>
  },
  {
    name: 'Seller',
    selector: 'seller2',
    sortable: true,
    minWidth: '150px !important',
    cell: row => <span title={row.seller2}>{row.seller2}</span>
  }
])


const prospectingColumns = memoize((type, handleAction) => [
 
  {
    cell: row => <Label id={row.propertyId} className='prospecting-address' /*onMouseEnter*/ basic onClick={() => {handleAction(row.propertyId)}/*this.props.handleAddressClicked ? this.props.handleAddressClicked.bind(this, row) : (e) => {}*/}>{row.address}</Label>,
    name: 'Address',
    selector: 'address',
    sortable: true,
    minWidth: '150px !important',
    maxWidth: '320px',
    //grow: 4,
    allowOverflow: true,
    button: false
  },
  {
    name: `${type === 'pluto' ? 'Borough' : 'City'}`,
    selector: `${type === 'pluto' ? 'borough' : 'city'}`,
    sortable: true,
    center: `${type === 'pluto' ? true : false}`,
    width: `${type === 'pluto' ? '60px !important' : ''}`
  },
  {
    name: 'Zip',
    selector: 'zip',
    sortable: true,
    center: true
  },
  {
    name: 'Current Owner',
    selector: 'ownerName',
    sortable: true,
    minWidth: '150px !important',
    cell: row => (<span className='prospecting-lender-name' title={row.ownerName}>{row.ownerName}</span>)
  },
  {
    name: 'Purchase Date',
    selector: 'saleDate',
    sortable: true,
    center: true,
    //width: '120px !important',
    format: row => (row.saleDate ?  <span>{formatDate(row.saleDate)}</span> : '-')
  },
  {
    name: 'Purchase Price',
    selector: 'saleAmount',
    sortable: true,
    center: true,
    grow: 1.5,
    //width: '125px !important',
    cell : row => (row.saleAmount ? <span>
        {type === 'pluto' ? (
            (<a style={{color:'inherit'}} href={acris_document_viewer_url + row.saleId} target="_blank" rel="noopener noreferrer">${formatNumber((row.saleAmount))}</a>)
        ) : <>${formatNumber((row.saleAmount))}</> }
    </span> : '-')
  },
  {
    name: 'Borrower',
    selector: 'borrower1',
    sortable: true,
    minWidth: '150px !important',
    cell : row => (
      <Popup content={row.borrowers && row.borrowers.length > 0 ? (
        <GridForPopup
        list={row.borrowers}
        isList={true}
        />
       ) : null} 

     trigger={<div className='prospecting-lender-name'><span>{row.borrower1}</span></div>}
     />
    )
  },
  {
    name: 'Seller',
    selector: 'seller1',
    sortable: true,
    minWidth: '150px !important',
    cell : row => (
      <Popup content={row.sellers && row.sellers.length > 0 ? (
        <GridForPopup
        list={row.sellers}
        isList={true}
        />
       ) : null} 

     trigger={<div className='prospecting-lender-name'><span>{row.seller1}</span></div>}
     />
    )
  },
  {
    name: 'Lender',
    selector: 'lender1',
    sortable: true,
    minWidth: '150px !important',
    cell : row => (
      <Popup content={row.lenders && row.lenders.length > 0 ? (
        <GridForPopup
        list={row.lenders}
        isList={true}
        />
       ) : null} 

     trigger={<div className='prospecting-lender-name'><span>{row.lenders[0]}</span></div>}
     />
    )
  },
  {
    name: 'Loan Date',
    selector: 'loan1Date',
    sortable: true,
    center: true,
    //width: '120px !important',
    cell: row => (
      <>
        {row.loanDates && row.loanDates.length > 1 ? (
          <Popup
            content={
              <GridForPopup
                list={row.loanDates.map(_ => formatDate(_))}
                isList={true}
              />
            }
            trigger={
              <div className='prospecting-lender-name'>
                <span>{row.loan1Date ? formatDate(row.loan1Date) : '-'}</span>
              </div>
            }
          />
        ) : (
          <div className='prospecting-lender-name'>
            <span>{row.loan1Date ? formatDate(row.loan1Date) : '-'}</span>
          </div>
        )}
      </>
    )
  },
  {
    name: 'Loan Amount',
    selector: 'loan1Amount',
    sortable: true,
    center: true,
    //width: '125px !important',
    cell: row => (
      <>
        {row.loanAmounts && row.loanAmounts.length > 1 ? (
          <Popup
            content={
              <GridForPopup
                list={row.loanAmounts.map(_ => {
                  return '$' + formatNumber(_);
                })}
                isList={true}
              />
            }
            trigger={
              <div className='prospecting-lender-name'>
                {type === 'pluto' ? (
                  <a
                    style={{ color: 'inherit' }}
                    href={acris_document_viewer_url + row.loan1Id}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ${formatNumber(row.loan1Amount)}
                  </a>
                ) : (
                  <>${formatNumber(row.loan1Amount)}</>
                )}
              </div>
            }
          />
        ) : (
          <div className='prospecting-lender-name'>
            <span>
              {row.loan1Amount ? (
                <>
                  {type === 'pluto' ? (
                    <a
                      style={{ color: 'inherit' }}
                      href={acris_document_viewer_url + row.loan1Id}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ${formatNumber(row.loan1Amount)}
                    </a>
                  ) : (
                    <>${formatNumber(row.loan1Amount)}</>
                  )}
                </>
              ) : (
                '-'
              )}
            </span>
          </div>
        )}
      </>
    )
  },
  {
    name: 'Bldg Sqft',
    selector: 'bldgArea',
    sortable: true,
    center: true,
    width: '100px !important',
    format: row => (formatNumber(row.bldgArea))
  },
  {
    name: 'Stories',
    selector: 'stories',
    sortable: true,
    center: true,
    //width: '60px !important'
  },
  // {
  //   name: 'Bldg Size',
  //   selector: 'bldgSize',
  //   sortable: true,
  //   center: true,
  //   //width: '120px !important',
  //   format: row => (Lot.bldgSizeString(row))
  // },
  {
    name: 'Total Units',
    selector: 'unitstotal',
    sortable: true,
    center: true,
    //width: '60px !important',
    cell: row => (<span title={`Residential: ${Lot.residentialUnits(row)}\nCommercial: ${Lot.commericalUnits(row)}`}>{Lot.totalUnits(row)}</span>)
  },
  {
    name: 'Lot Size',
    selector: 'lotSize',
    sortable: true,
    center: true,
    grow: 1.5,
   // width: '120px !important',
    format: row => (Lot.lotSizeString(row))
  },
  {
    name: 'Lot Sqft',
    selector: 'lotArea',
    sortable: true,
    center: true,
    //width: '90px !important',
    format: row => (formatNumber(row.lotArea))
  },
  {
    name: 'Year Built',
    selector: 'yearBuilt',
    sortable: true,
    center: true
    //right: true,
    //width: '90px !important'
  },
  {
    name: 'Zoning',
    selector: 'zoning',
    sortable: false,
    center: true,
    cell: row => (<span style={{textOverflow: 'ellipsis'}} title={row.zoning}>{row.zoning}</span>)
    //width: '90px !important'
  },
  {
    name: 'Neighborhood',
    selector: 'neighborhood',
    //minWidth: '150px !important',
    //maxWidth: '250px !important',
    center: true,
    sortable: true,
    format: row => (<span title={row.neighborhood}>{row.neighborhood}</span>)
  },
])

const columnsV3 = memoize((handleAction, bucket, type) => [
  {
    name: 'Rank',
    selector: 'finalBuckets',
    sortable: true,
    center: true,
    // minWidth: '50px !important',
    grow: .5,
    maxWidth: '100px !important',
    format: row => (
      <span title={getDisabledReasons(row)}>
      {
        row.rank ? (
          `${row.rank} ${
            row.finalBuckets && row.finalBuckets.length > 0 
              ? '(' + row.finalBuckets.map(bucketShortName).join(',') + ')' 
              : row.isDisabled ? '(D)' : ''
          }`
        ) : (
          row.finalBuckets && row.finalBuckets.length > 0 
            ? row.finalBuckets.map(bucketShortName).join(',')
            : row.isDisabled ? '(D)' : '-'
        )
      }
    </span>
    )
  },
  {
    cell: row => <Label id={row.propertyId} className='comps-address' /*onMouseEnter*/ basic onClick={() => {handleAction(row.propertyId)}/*this.props.handleAddressClicked ? this.props.handleAddressClicked.bind(this, row) : (e) => {}*/}>{row.address}</Label>,
    name: 'Address',
    selector: 'address',
    sortable: true,
    minWidth: '220px !important',
    maxWidth: '320px',
    //grow: 4,
    allowOverflow: true,
    button: false
  },
  {
    name: 'LTDS',
    selector: 'ltds',
    sortable: true,
    center: true,
    grow: .5,
    format: row => <span style={row.composyteLikenessScore > 65 && row.composyteTimeDistanceScore > 65 && row.composyteSizeScore > 65 ? {backgroundColor: 'green', color: 'white'} : null}>
      {row.ltds !== null ? `${formatNumber(row.ltds)}%` : '-'}
      </span>
  },
  {
    name: 'Z Score',
    selector: 'bucketZScore',
    sortable: true,
    center: true,
    format: row => (row.bucketZScore ? `${formatNumber(row.bucketZScore)}` : '-')
  },
  {
    name: 'Sector',
    selector: 'sector',
    sortable: true,
    center: true
  },
  {
    name: 'Zip',
    selector: 'zip',
    sortable: true,
    center: true
  },
  {
    name: 'Distance',
    selector: 'distance',
    sortable: true,
    center: true,
    cell: row => (
      <span title={`${formatNumber(row.distance, 3)}`}>
        {formatNumber(row.distance)} mi
      </span>
    )
  },
  {
    name: `${type === CompTypeOption.DEED || type === CompTypeOption.NEIGHBORHOOD ? 'Sale Price' : type === CompTypeOption.MORTGAGE ? 'Loan Amount'  : type === CompTypeOption.LISTING ? 'List Price' : ''}`,
    selector: 'documentAmount',
    sortable: true,
    center: true,
    //width: '125px !important',
    cell : row => (
      <span style={{whiteSpace: 'nowrap'}}>
        {type === CompTypeOption.DEED || type === CompTypeOption.NEIGHBORHOOD || type === CompTypeOption.MORTGAGE ? (<a style={{color:'inherit'}} href={acris_document_viewer_url + row.documentId} target="_blank" rel="noopener noreferrer">
          ${formatNumber((row.documentAmount))} {type === CompTypeOption.DEED || type === CompTypeOption.NEIGHBORHOOD? (row.withinTwoYears && '(F)') : type === CompTypeOption.MORTGAGE ? (row.isPurchaseMortgage && '(P)') : ''}
        </a>) : (<>${formatNumber((row.documentAmount))} {type === CompTypeOption.LISTING ? (row.withinTwoYears && '(F)') : ''}</>) }
      </span>
      )
  },
  {
    name: `${type === CompTypeOption.LISTING ? 'Date Listed' : 'Date Closed'}`,
    selector: 'documentDate',
    sortable: true,
    center: true,
    //width: '120px !important',
    format: row => (formatDate(row.documentDate))
  },
  ...[(type === CompTypeOption.LISTING || type === CompTypeOption.RENT ? {
    name: 'Status',
    selector: 'listingStatus',
    sortable: true,
    center: true
  } : {})],
  {
    name: `${type === CompTypeOption.DEED || type === CompTypeOption.NEIGHBORHOOD ? 'Days From Sale' : type === CompTypeOption.MORTGAGE ? 'Days From Loan' :  type === CompTypeOption.LISTING || type === CompTypeOption.RENT ? 'Days Active' : ''}`,
    selector: 'daysFromDocument',
    sortable: true,
    center: true,
    //width: '120px !important',
    format: row => (formatNumber(row.daysFromSale))
  },
  {
    name: 'Bldg Sqft',
    selector: 'bldgArea',
    sortable: true,
    center: true,
    width: '100px !important',
    format: row => (formatNumber(row.bldgArea))
  },
  {
    name: `${type === CompTypeOption.DEED || type === CompTypeOption.NEIGHBORHOOD || type === CompTypeOption.LISTING ? 'Price/Sqft' : type === CompTypeOption.MORTGAGE ? 'Loan/Sqft' : type === CompTypeOption.RENT ? 'Rent/Bedroom' : ''}`,
    selector: 'pricePerSqft',
    sortable: true,
    center: true,
    format: row => (`$${formatNumber(row.pricePerSqft)}`)
  },
  {
    name: `${type === CompTypeOption.DEED || type === CompTypeOption.NEIGHBORHOOD ? 'Seller & Buyer' : type === CompTypeOption.MORTGAGE ? 'Lender & Borrower'  :  type === CompTypeOption.LISTING || type === CompTypeOption.RENT ? 'Listing Agent' : ''}`,
    selector: 'buyersAndSellers',
    sortable: false,
    width: '200px !important',
    cell: row => (
      <>
      {type === CompTypeOption.LISTING || type === CompTypeOption.RENT ? (
        (<div className="deed-party-container"><span className="deed-party" title={row.listingOffice?.name}>{row.listingOffice?.name}</span><span className="deed-party" title={row.listingAgent?.name}>{row.listingAgent?.name}</span></div>)
      ) : (<div className="deed-party-container"><span className="deed-party" title={row.sellerNames}>{row.sellerNames}</span><span className="deed-party" title={row.buyerNames}>{row.buyerNames}</span></div>)
      }</>
    )
  },
  {
    name: 'Time Held',
    selector: 'heldString',
    sortable: false,
    center: true,
    //width: '125px !important',
    //cell: row => (row.heldString)
    cell: row => type === CompTypeOption.DEED || type === CompTypeOption.NEIGHBORHOOD || type === CompTypeOption.LISTING ? row.heldString : 'N/A'
  },
  {
    name: 'Stories',
    selector: 'stories',
    sortable: true,
    center: true,
    //width: '60px !important'
  },
  {
    name: 'Bldg Size',
    selector: 'bldgSize',
    sortable: true,
    center: true,
    //width: '120px !important',
    format: row => (Lot.bldgSizeString(row))
  },
  {
    name: 'Total Units',
    selector: 'totalUnits',
    sortable: true,
    center: true,
    //width: '60px !important',
    cell: row => (<span title={`Residential: ${row.residentialUnits}\nCommercial: ${row.commercialUnits}`}>{row.totalUnits}</span>)
  },
  {
    name: 'Zoning',
    selector: 'zoning',
    sortable: false,
    center: true,
    cell: row => (<span style={{textOverflow: 'ellipsis'}} title={row.zoning}>{row.zoning}</span>)
    //width: '90px !important'
  },
  {
    name: 'Lot Size',
    selector: 'lotSize',
    sortable: true,
    center: true,
   // width: '120px !important',
    format: row => (Lot.lotSizeString(row))
  },
  {
    name: 'Lot Sqft',
    selector: 'lotArea',
    sortable: true,
    center: true,
    //width: '90px !important',
    format: row => (formatNumber(row.lotArea))
  },
  {
    name: 'Year Built',
    selector: 'yearBuilt',
    sortable: true,
    center: true
    //right: true,
    //width: '90px !important'
  },
  {
    name: 'City',
    selector: 'city',
    //minWidth: '150px !important',
    //maxWidth: '250px !important',
    center: true,
    sortable: true,
    format: row => (<span title={row.city}>{row.city}</span>)
  },
  {
    name: 'Neighborhood',
    selector: 'neighborhood',
    //minWidth: '150px !important',
    //maxWidth: '250px !important',
    center: true,
    sortable: true,
    format: row => (<span title={row.neighborhood}>{row.neighborhood}</span>)
  },
  {
    name: 'Flood Zone',
    selector: 'floodZone',
    sortable: true,
    center: true
  },
  {
    name: 'Flood Score',
    selector: 'floodScore',
    sortable: true,
    center: true,
    format: row => formatNumber(row.floodScore)
  },
  {
    name: 'Likeness',
    selector: 'composyteLikenessScore',
    sortable: true,
    center: true,
    grow: .5,
    cell: row => ( <span> <Popup position='left center' content={<GridForPopup
      //title='Breakdown'
      list={row.likenessBreakdown}
      titleKey='label'
      keyName='score'
      />}

     trigger={<span style={{cursor: 'pointer'}}>{formatNumber(row.composyteLikenessScore)+'%'}</span>} 
   />
   </span>) 
  },
  {
    name: 'TD',
    selector: 'composyteTimeDistanceScore',
    sortable: true,
    center: true,
    grow: .5,
    cell: row => (<span> <Popup position='left center' content={(
      <GridForPopup
      //title='Breakdown'
      list={[
        // {
        //   label: '',
        //   score: '100 - [(D*T)/3.65]'
        // },
        {
          label: 'Distance',
          score: `(${formatNumber(row.distance, 3)} mi)`
        },
        {
          label: 'Time',
          score: `(${formatNumber(row.daysFromSale)} days)`
        },
        {
          label: 'Same Block',
          score: `${row.composyteSameBlockScore}`
        },
        {
          label: 'Neighborhood',
          score: `${row.composyteNeighborhoodScore}`
        }
      ]}
      titleKey='label'
      keyName='score'
      />
     )} 

     trigger={<span style={{cursor: 'pointer'}}>{formatNumber(row.composyteTimeDistanceScore)}%</span>} 
   />
   </span>) 
  },
  {
    name: 'Size',
    selector: 'composyteSizeScore',
    sortable: true,
    center: true,
    grow: .5,
    cell: row => (<span> <Popup position='left center' content={(
      <GridForPopup
      //title='Breakdown'
      list={[
        {
          label: '',
          score: '100'
        },
        {
          label: 'Bldg Area',
          score: `${formatNumber(row.composyteBldgAreaScore)}`
        },
        {
          label: 'Lot Area',
          score: `${formatNumber(row.composyteLotAreaScore)}`
        },
        {
          label: 'Lot Width',
          score: `${formatNumber(row.composyteLotWidthScore)}`
        },
        {
          label: 'Lot Depth',
          score: `${formatNumber(row.composyteLotDepthScore)}`
        },
        {
          label: 'Same Size',
          score: `${formatNumber(row.composyteLotSameSizeScore)}`
        },
      ]}
      titleKey='label'
      keyName='score'
      />
     )} 

     trigger={<span style={{cursor: 'pointer'}}>{`${formatNumber(row.composyteSizeScore)}%`}</span>} 
   />
   </span>) 
  },
  {
    cell: row => <Label id={row.propertyId} className='comps-address' /*onMouseEnter*/ basic onClick={() => {handleAction(row.propertyId)}/*this.props.handleAddressClicked ? this.props.handleAddressClicked.bind(this, row) : (e) => {}*/}>{row.address}</Label>,
    name: 'Address',
    selector: 'address',
    sortable: true,
    minWidth: '220px !important',
    maxWidth: '320px',
    //grow: 4,
    right: true,
    allowOverflow: true,
    button: false
  }
])

const faColumns = memoize((handleAction, bucket, type) => [
  {
    name: 'Rank',
    selector: 'finalBuckets',
    sortable: true,
    center: true,
    // minWidth: '50px !important',
    grow: .5,
    maxWidth: '100px !important',
    format: row => (
      <span title={getDisabledReasons(row)}>
      {
        row.rank ? (
          `${row.rank} ${
            row.finalBuckets && row.finalBuckets.length > 0 
              ? '(' + row.finalBuckets.map(bucketShortName).join(',') + ')' 
              : row.isDisabled ? '(D)' : ''
          }`
        ) : (
          row.finalBuckets && row.finalBuckets.length > 0 
            ? row.finalBuckets.map(bucketShortName).join(',')
            : row.isDisabled ? '(D)' : '-'
        )
      }
    </span>
    )
  },
  {
    name: 'LTDS',
    selector: 'ltds',
    sortable: true,
    center: true,
    grow: .5,
    format: row => <span style={row.composyteLikenessScore > 65 && row.composyteTimeDistanceScore > 65 && row.composyteSizeScore > 65 ? {backgroundColor: 'green', color: 'white'} : null}>
      {row.ltds !== null ? `${formatNumber(row.ltds)}%` : '-'}
      </span>
  },
  {
    name: 'Z Score',
    selector: 'bucketZScore',
    sortable: true,
    center: true,
    format: row => (row.bucketZScore ? `${formatNumber(row.bucketZScore)}` : '-')
  },
  {
    name: 'Match',
    selector: 'matchingTraits',
    sortable: true,
    width: '100px !important',
    cell: row => <div>
      {
        row.matchingTraits ? (
          <SignalBars numBars={row.matchingTraits} />
        ) : null 
      }
    </div>
  },
  {
    cell: row => <Label id={row.propertyId} className='comps-address' /*onMouseEnter*/ basic onClick={() => {handleAction(row.propertyId)}/*this.props.handleAddressClicked ? this.props.handleAddressClicked.bind(this, row) : (e) => {}*/}>{row.address}</Label>,
    name: 'Address',
    selector: 'address',
    sortable: true,
    minWidth: '200px !important',
    maxWidth: '320px',
    //grow: 4,
    allowOverflow: true,
    button: false
  },
  {
    name: `${type === CompTypeOption.DEED || type === CompTypeOption.NEIGHBORHOOD ? 'Price' : type === CompTypeOption.MORTGAGE ? 'Amount'  : type === CompTypeOption.LISTING ? 'Price' : type === CompTypeOption.RENT ? 'Rent' : ''}`,
    selector: 'documentAmount',
    sortable: true,
    center: true,
    //width: '125px !important',
    cell : row => (
      <span style={{whiteSpace: 'nowrap'}}>
        {type === CompTypeOption.DEED || type === CompTypeOption.NEIGHBORHOOD || type === CompTypeOption.MORTGAGE ? (<a style={{color:'inherit'}} href={acris_document_viewer_url + row.documentId} target="_blank" rel="noopener noreferrer">
          ${formatNumber((row.documentAmount))} {type === CompTypeOption.DEED || type === CompTypeOption.NEIGHBORHOOD ? (row.withinTwoYears && '(F)') : type === CompTypeOption.MORTGAGE ? (row.isPurchaseMortgage && '(P)') : ''}
        </a>) : (<>${formatNumber((row.documentAmount))} {type === CompTypeOption.LISTING ? (row.withinTwoYears && '(F)') : ''}</>) }
      </span>
      )
  },
  {
    name: `${type === CompTypeOption.DEED || type === CompTypeOption.NEIGHBORHOOD || type === CompTypeOption.LISTING ? 'PPSF' : type === CompTypeOption.MORTGAGE ? 'LPSF' : type === CompTypeOption.RENT ? 'RPBR' : ''}`,
    selector: 'pricePerSqft',
    sortable: true,
    center: true,
    format: row => (`$${formatNumber(row.pricePerSqft)}`)
  },
  {
    name: 'Days',
    selector: 'daysFromDocument',
    sortable: true,
    center: true,
    //width: '120px !important',
    format: row => (formatNumber(row.daysFromSale))
  },
  {
    name: 'Distance',
    selector: 'distance',
    sortable: true,
    center: true,
    cell: row => (
      <span title={`${formatNumber(row.distance, 3)}`}>
        {formatNumber(row.distance)} mi
      </span>
    )
  },
  {
    name: 'Match',
    selector: 'sameSizeAreas',
    sortable: true,
    center: true,
    cell: row => row.listingArea === null || row.listingArea === 0 ? <span>-</span> : row.sameSizeAreas.length === 0 ? <span>X</span> : (<span title={row.sameSizeAreas.join(',')}>O</span>)
  },
  {
    name: 'Final Sqft',
    selector: 'finalArea',
    sortable: true,
    center: true,
    width: '100px !important',
    format: row => (formatNumber(row.finalArea))
  },
  {
    name: 'Total Sqft',
    selector: 'totalArea',
    sortable: true,
    center: true,
    width: '100px !important',
    format: row => (formatNumber(row.totalArea))
  },
  {
    name: 'Urbyn Choice',
    selector: 'urbynArea',
    sortable: true,
    center: true,
    width: '100px !important',
    format: row => (formatNumber(row.urbynArea))
  },
  {
    name: 'FA Choice',
    selector: 'faArea',
    sortable: true,
    center: true,
    width: '100px !important',
    format: row => (formatNumber(row.faArea))
  },
  {
    name: 'Living Sqft',
    selector: 'livingArea',
    sortable: true,
    center: true,
    width: '100px !important',
    format: row => (formatNumber(row.livingArea))
  },
  {
    name: 'Listing',
    selector: 'listingArea',
    sortable: true,
    center: true,
    width: '100px !important',    
    format: row => <span style={{color: row.useListingArea ? 'blue' : 'inherit'}}>{(formatNumber(row.listingArea))}</span>
  },
  {
    name: 'Basement',
    selector: 'basementArea',
    sortable: true,
    center: true,
    width: '100px !important',
    format: row => (formatNumber(row.basementArea))
  },
  {
    name: 'Garage',
    selector: 'garageArea',
    sortable: true,
    center: true,
    width: '100px !important',
    format: row => (formatNumber(row.garageArea))
  },
  {
    name: 'Attic',
    selector: 'atticArea',
    sortable: true,
    center: true,
    width: '100px !important',
    format: row => (formatNumber(row.atticArea))
  },
  {
    name: 'Units',
    selector: 'totalUnits',
    sortable: true,
    center: true,
    //width: '60px !important',
    cell: row => (<span title={`Residential: ${row.residentialUnits}\nCommercial: ${row.commercialUnits}`}>{row.totalUnits}</span>)
  },
  {
    name: 'Stories',
    selector: 'stories',
    sortable: true,
    center: true,
    //width: '60px !important'
  },
  {
    name: 'Bedrooms/Bathrooms',
    selector: 'bedrooms',
    sortable: true,
    center: true,
    //width: '60px !important'
    cell: row => (<span>{row.bedrooms}/{row.bathrooms}</span>)
  },
  {
    name: 'Age',
    selector: 'yearBuilt',
    sortable: true,
    center: true
    //right: true,
    //width: '90px !important'
  },
  {
    name: 'Held',
    selector: 'heldString',
    sortable: true,
    center: true
    //right: true,
    //width: '90px !important'
  },
  {
    name: 'Lot Size',
    selector: 'lotSize',
    sortable: true,
    center: true,
   // width: '120px !important',
    format: row => (Lot.lotSizeString(row))
  },
  {
    name: 'Lot Sqft',
    selector: 'lotArea',
    sortable: true,
    center: true,
    //width: '90px !important',
    format: row => (formatNumber(row.lotArea))
  },
  {
    name: 'Style',
    selector: 'style',
    sortable: true,
    center: true
    //right: true,
    //width: '90px !important'
  },
  {
    name: 'Investor',
    sortable: false,
    center: true,
    cell: row => `${row.sellerIsInvestor ? '(S)' : ''}${row.buyerIsInvestor ? '(B)' : ''}`
  },
  {
    name: `${type === CompTypeOption.DEED || type === CompTypeOption.NEIGHBORHOOD ? 'Seller & Buyer' : type === CompTypeOption.MORTGAGE ? 'Lender & Borrower'  :  type === CompTypeOption.LISTING || type === CompTypeOption.RENT ? 'Listing Agent' : ''}`,
    selector: 'buyersAndSellers',
    sortable: false,
    width: '200px !important',
    cell: row => (
      <>
      {type === CompTypeOption.LISTING || type === CompTypeOption.RENT ? (
        (<div className="deed-party-container"><span className="deed-party" title={row.listingOffice?.name}>{row.listingOffice?.name}</span><span className="deed-party" title={row.listingAgent?.name}>{row.listingAgent?.name}</span></div>)
      ) : (<div className="deed-party-container"><span className="deed-party" title={row.sellerNames}>{row.sellerNames}</span><span className="deed-party" title={row.buyerNames}>{row.buyerNames}</span></div>)
      }</>
    )
  },
  {
    name: `${type === CompTypeOption.LISTING || type === CompTypeOption.RENT ? 'Listed' : 'Closed'}`,
    selector: 'documentDate',
    sortable: true,
    center: true,
    //width: '120px !important',
    format: row => (formatDate(row.documentDate))
  },
  ...[(type === CompTypeOption.LISTING || type === CompTypeOption.RENT ? {
    name: 'Status',
    selector: 'listingStatus',
    sortable: true,
    center: true
  } : {})],
  {
    name: 'Sector',
    selector: 'sector',
    sortable: true,
    center: true
  },
  {
    name: 'City',
    selector: 'city',
    //minWidth: '150px !important',
    //maxWidth: '250px !important',
    center: true,
    sortable: true,
    format: row => (<span title={row.city}>{row.city}</span>)
  },
  {
    name: 'Town',
    selector: 'neighborhood',
    //minWidth: '150px !important',
    //maxWidth: '250px !important',
    center: true,
    sortable: true,
    format: row => (<span title={row.neighborhood}>{row.neighborhood}</span>)
  },
  {
    name: 'Subdivision',
    selector: 'subdivision',
    minWidth: '150px !important',
    maxWidth: '250px !important',
    center: true,
    sortable: true,
    format: row => (<span title={row.subdivision}>{row.subdivision}</span>)
  },
  {
    name: 'Description',
    selector: 'legalDescription',
    minWidth: '150px !important',
    maxWidth: '250px !important',
    center: true,
    sortable: true,
    format: row => (<span title={row.legalDescription}>{row.legalDescription}</span>)
  },
  {
    name: 'School District',
    selector: 'schoolDistrict',
    minWidth: '150px !important',
    maxWidth: '250px !important',
    center: true,
    sortable: true,
    format: row => (<span title={row.schoolDistrict}>{row.schoolDistrict}</span>)
  },
  {
    name: 'Zip',
    selector: 'zip',
    sortable: true,
    center: true
  },
  {
    name: 'Flood Zone',
    selector: 'floodZone',
    sortable: true,
    center: true
  },
  {
    name: 'Flood Score',
    selector: 'floodScore',
    sortable: true,
    center: true,
    format: row => formatNumber(row.floodScore)
  },
  {
    name: 'L',
    selector: 'composyteLikenessScore',
    sortable: true,
    center: true,
    grow: .5,
    cell: row => row.bucket !== 'Subject' && row.composyteLikenessScore !== null ? ( <span> <Popup position='left center' content={<GridForPopup
      //title='Breakdown'
      list={row.likenessBreakdown}
      titleKey='label'
      keyName='score'
      />}

     trigger={<span style={{cursor: 'pointer'}}>{formatNumber(row.composyteLikenessScore)+'%'}</span>} 
   />
   </span>) : '-'
  },
  {
    name: 'TD',
    selector: 'composyteTimeDistanceScore',
    sortable: true,
    center: true,
    grow: .5,
    cell: row => row.bucket !== 'Subject' && row.composyteTimeDistanceScore !== null ? (<span> <Popup position='left center' content={(
      <GridForPopup
      //title='Breakdown'
      list={[
        // {
        //   label: '',
        //   score: '100 - [(D*T)/3.65]'
        // },
        {
          label: 'Distance',
          score: `(${formatNumber(row.distance, 3)} mi)`
        },
        {
          label: 'Time',
          score: `(${formatNumber(row.daysFromSale)} days)`
        },
        {
          label: 'TD',
          score: `-${formatNumber(row.composyteTimeDistanceDeduction)}`
        },
        {
          label: 'Neighborhood',
          score: `${row.composyteNeighborhoodScore}`
        },
        {
          label: 'City',
          score: `${row.composyteCityScore}`
        },
        {
          label: 'Flood Zone',
          score: `${row.composyteFloodZoneScore}`
        },
        {
          label: 'Zip',
          score: `${row.composyteZipScore}`
        },
        {
          label: 'School District',
          score: `${row.composyteSchoolDistrictScore}`
        },
        {
          label: 'Subdivision',
          score: `${row.composyteSubdivisionScore}`
        },
        {
          label: 'Same Block',
          score: `${row.composyteSameBlockScore}`
        }
      ]}
      titleKey='label'
      keyName='score'
      />
     )} 

     trigger={<span style={{cursor: 'pointer'}}>{formatNumber(row.composyteTimeDistanceScore)}%</span>} 
   />
   </span>) : '-'
  },
  {
    name: 'S',
    selector: 'composyteSizeScore',
    sortable: true,
    center: true,
    grow: .5,
    cell: row => row.bucket !== 'Subject' && row.composyteSizeScore !== null ? (<span> <Popup position='left center' content={(
      <GridForPopup
      //title='Breakdown'
      list={[
        {
          label: '',
          score: '100'
        },
        {
          label: 'Bldg Area',
          score: `${formatNumber(row.composyteBldgAreaScore)}`
        },
        {
          label: 'Lot Area',
          score: `${formatNumber(row.composyteLotAreaScore)}`
        },
        // {
        //   label: 'Urbyn Area',
        //   score: `${formatNumber(row.composyteUrbynAreaScore)}`
        // },
        // {
        //   label: 'FA Area',
        //   score: `${formatNumber(row.composytefaAreaScore)}`
        // },
        {
          label: `${row.compsVersion === 9 ? 'Total Area' : 'Living Area'}`,
          score: `${formatNumber(row.composyteLivingAreaScore)}`
        },
        {
          label: 'Basement Area',
          score: `${formatNumber(row.composyteBasementAreaScore)}`
        },
        {
          label: 'Garage Area',
          score: `${formatNumber(row.composyteGarageAreaScore)}`
        },
        {
          label: 'Attic Area',
          score: `${formatNumber(row.composyteAtticAreaScore)}`
        },
        {
          label: 'Lot Width',
          score: `${formatNumber(row.composyteLotWidthScore)}`
        },
        {
          label: 'Lot Depth',
          score: `${formatNumber(row.composyteLotDepthScore)}`
        },
        {
          label: 'Same Size',
          score: `${formatNumber(row.composyteLotSameSizeScore)}`
        },
        {
          label: 'Bedrooms',
          score: `${formatNumber(row.composyteBedroomsScore)}`
        },
        {
          label: 'Bathrooms',
          score: `${formatNumber(row.composyteBathroomsScore)}`
        },
      ]}
      titleKey='label'
      keyName='score'
      />
     )} 

     trigger={<span style={{cursor: 'pointer'}}>{`${formatNumber(row.composyteSizeScore)}%`}</span>} 
   />
   </span>) : '-'
  },
  {
    cell: row => <Label id={row.propertyId} className='comps-address' /*onMouseEnter*/ basic onClick={() => {handleAction(row.propertyId)}/*this.props.handleAddressClicked ? this.props.handleAddressClicked.bind(this, row) : (e) => {}*/}>{row.address}</Label>,
    name: 'Address',
    selector: 'address',
    sortable: true,
    minWidth: '200px !important',
    maxWidth: '320px',
    //grow: 4,
    right: true,
    allowOverflow: true,
    button: false
  }
])


const columnsRentalsV3 = memoize((handleAction, bucket, type) => [
  {
    name: 'Rank',
    selector: 'finalBuckets',
    sortable: true,
    center: true,
    // minWidth: '50px !important',
    grow: .5,
    maxWidth: '100px !important',
    format: row => (
      <span title={getDisabledReasons(row)}>
      {
        row.rank ? (
          `${row.rank} ${
            row.finalBuckets && row.finalBuckets.length > 0 
              ? '(' + row.finalBuckets.map(bucketShortName).join(',') + ')' 
              : row.isDisabled ? '(D)' : ''
          }`
        ) : (
          row.finalBuckets && row.finalBuckets.length > 0 
            ? row.finalBuckets.map(bucketShortName).join(',')
            : row.isDisabled ? '(D)' : '-'
        )
      }
    </span>
    )
  },
  {
    cell: row => <Label id={row.propertyId} className='comps-address' /*onMouseEnter*/ basic onClick={() => {handleAction(row.propertyId)}/*this.props.handleAddressClicked ? this.props.handleAddressClicked.bind(this, row) : (e) => {}*/}>{row.address}</Label>,
    name: 'Address',
    selector: 'address',
    sortable: true,
    minWidth: '220px !important',
    maxWidth: '320px',
    //grow: 4,
    allowOverflow: true,
    button: false
  },
  // {
  //   name: 'Unit',
  //   selector: 'unit',
  //   sortable: false,
  //   center: true,
  //   format: row => row.rooms?.unit
  // },
  {
    name: 'LTDS',
    selector: 'ltds',
    sortable: true,
    center: true,
    grow: .5,
    format: row => (row.ltds !== null ? `${formatNumber(row.ltds)}%` : '-')
  },
  {
    name: 'Sector',
    selector: 'sector',
    sortable: true,
    center: true
  },
  {
    name: 'Zip',
    selector: 'zip',
    sortable: true,
    center: true
  },
  {
    name: 'Distance',
    selector: 'distance',
    sortable: true,
    center: true,
    cell: row => (
      <span title={`${formatNumber(row.distance, 3)}`}>
        {formatNumber(row.distance)} mi
      </span>
    )
  },
  {
    name: 'Rent Amount',
    selector: 'documentAmount',
    sortable: true,
    center: true,
    //width: '125px !important',
    cell : row => (
      <span style={{whiteSpace: 'nowrap'}}>
        ${formatNumber((row.documentAmount))}
      </span>
      )
  },
  {
    name: 'Date Listed',
    selector: 'documentDate',
    sortable: true,
    center: true,
    //width: '120px !important',
    format: row => (formatDate(row.documentDate))
  },
  {
    name: 'Status',
    selector: 'listingStatus',
    sortable: true,
    center: true,
  },
  {
    name: 'Days Active',
    selector: 'daysFromDocument',
    sortable: true,
    center: true,
    //width: '120px !important',
    format: row => (formatNumber(row.daysFromSale))
  },
  {
    name: 'Bedrooms',
    selector: 'bedrooms',
    sortable: false,
    center: true,
  },
  {
    name: 'Bathrooms',
    selector: 'bathrooms',
    sortable: false,
    center: true,
  },
  {
    name: 'Total Rooms',
    selector: 'rooms',
    sortable: false,
    center: true,
  },
  {
    name: 'Bldg Sqft',
    selector: 'bldgArea',
    sortable: true,
    center: true,
    width: '100px !important',
    format: row => (formatNumber(row.bldgArea))
  },
  {
    name: 'Rent/Bedroom',
    selector: 'pricePerSqft',
    sortable: true,
    center: true,
    format: row => (`$${formatNumber(row.pricePerSqft)}`)
  },
  {
    name: 'Listing Agent',
    selector: 'buyersAndSellers',
    sortable: false,
    width: '200px !important',
    cell: row => (
      (<div className="deed-party-container"><span className="deed-party" title={row.listingOffice?.name}>{row.listingOffice?.name}</span><span className="deed-party" title={row.listingAgent?.name}>{row.listingAgent?.name}</span></div>)
    )
  },
  {
    name: 'Stories',
    selector: 'stories',
    sortable: true,
    center: true,
    //width: '60px !important'
  },
  {
    name: 'Bldg Size',
    selector: 'bldgSize',
    sortable: true,
    center: true,
    //width: '120px !important',
    format: row => (Lot.bldgSizeString(row))
  },
  {
    name: 'Total Units',
    selector: 'totalUnits',
    sortable: true,
    center: true,
    //width: '60px !important',
    cell: row => (<span title={`Residential: ${row.residentialUnits}\nCommercial: ${row.commercialUnits}`}>{row.totalUnits}</span>)
  },
  {
    name: 'Zoning',
    selector: 'zoning',
    sortable: false,
    center: true,
    cell: row => (<span style={{textOverflow: 'ellipsis'}} title={row.zoning}>{row.zoning}</span>)
    //width: '90px !important'
  },
  {
    name: 'Lot Size',
    selector: 'lotSize',
    sortable: true,
    center: true,
   // width: '120px !important',
    format: row => (Lot.lotSizeString(row))
  },
  {
    name: 'Lot Sqft',
    selector: 'lotArea',
    sortable: true,
    center: true,
    //width: '90px !important',
    format: row => (formatNumber(row.lotArea))
  },
  {
    name: 'Year Built',
    selector: 'yearBuilt',
    sortable: true,
    center: true
    //right: true,
    //width: '90px !important'
  },
  {
    name: 'City',
    selector: 'city',
    //minWidth: '150px !important',
    //maxWidth: '250px !important',
    center: true,
    sortable: true,
    format: row => (<span title={row.city}>{row.city}</span>)
  },
  {
    name: 'Neighborhood',
    selector: 'neighborhood',
    //minWidth: '150px !important',
    //maxWidth: '250px !important',
    center: true,
    sortable: true,
    format: row => (<span title={row.neighborhood}>{row.neighborhood}</span>)
  },
  {
    name: 'Flood Zone',
    selector: 'floodZone',
    sortable: true,
    center: true
  },
  {
    name: 'Flood Score',
    selector: 'floodScore',
    sortable: true,
    center: true,
    format: row => formatNumber(row.floodScore)
  },
  {
    name: 'Likeness',
    selector: 'composyteLikenessScore',
    sortable: true,
    center: true,
    grow: .5,
    cell: row => ( <span> <Popup position='left center' content={<GridForPopup
      //title='Breakdown'
      list={row.likenessBreakdown}
      titleKey='label'
      keyName='score'
      />}

     trigger={<span style={{cursor: 'pointer'}}>{formatNumber(row.composyteLikenessScore)+'%'}</span>} 
   />
   </span>) 
  },
  {
    name: 'TD',
    selector: 'composyteTimeDistanceScore',
    sortable: true,
    center: true,
    grow: .5,
    cell: row => (<span> <Popup position='left center' content={(
      <GridForPopup
      //title='Breakdown'
      list={[
        // {
        //   label: '',
        //   score: '100 - [(D*T)/3.65]'
        // },
        {
          label: 'Distance',
          score: `(${formatNumber((row.distance))} mi)`
        },
        {
          label: 'Time',
          score: `(${formatNumber(row.daysFromSale)} days)`
        },
        {
          label: 'Same Block',
          score: `${row.composyteSameBlockScore}`
        },
        {
          label: 'Neighborhood',
          score: `${row.composyteNeighborhoodScore}`
        }
      ]}
      titleKey='label'
      keyName='score'
      />
     )} 

     trigger={<span style={{cursor: 'pointer'}}>{formatNumber(row.composyteTimeDistanceScore)}%</span>} 
   />
   </span>) 
  },
  {
    name: 'Size',
    selector: 'composyteSizeScore',
    sortable: true,
    center: true,
    grow: .5,
    cell: row => (<span> <Popup position='left center' content={(
      <GridForPopup
      //title='Breakdown'
      list={[
        {
          label: '',
          score: '100'
        },
        {
          label: 'Bldg Area',
          score: `${formatNumber(row.composyteBldgAreaScore)}`
        },
        {
          label: 'Lot Area',
          score: `${formatNumber(row.composyteLotAreaScore)}`
        },
        {
          label: 'Lot Width',
          score: `${formatNumber(row.composyteLotWidthScore)}`
        },
        {
          label: 'Lot Depth',
          score: `${formatNumber(row.composyteLotDepthScore)}`
        },
        {
          label: 'Same Size',
          score: `${formatNumber(row.composyteLotSameSizeScore)}`
        },
      ]}
      titleKey='label'
      keyName='score'
      />
     )} 

     trigger={<span style={{cursor: 'pointer'}}>{formatNumber(row.composyteSizeScore)}%</span>} 
   />
   </span>) 
  },
  {
    cell: row => <Label id={row.propertyId} className='comps-address' /*onMouseEnter*/ basic onClick={() => {handleAction(row.propertyId)}/*this.props.handleAddressClicked ? this.props.handleAddressClicked.bind(this, row) : (e) => {}*/}>{row.address}</Label>,
    name: 'Address',
    selector: 'address',
    sortable: true,
    minWidth: '220px !important',
    maxWidth: '320px',
    //grow: 4,
    right: true,
    allowOverflow: true,
    button: false
  }
])

export class CompsTableV3 extends Component {
  
  customSort = (rows, field, direction) => {
    const {selectedLot} = this.props;
  
    const handleField = row => {
      if (field === 'finalBuckets') {
        // Ensure rows with finalBuckets are considered first
        if (row.finalBuckets && row.finalBuckets.length > 0) {
          return 1; // Row has finalBuckets
        }
        return 0; // Row does not have finalBuckets
      }
  
      if (field === 'finalPercentile') {
        const {rankings} = row;
        if (rankings && rankings.length > 0) {
          return row.rankings[0].finalPercentile;
        }
      } else if (field === 'totalArea') {
        return (row.livingArea ?? 0) + (row.garageArea ?? 0) + (row.basementArea ?? 0);
      } else if (field === 'sameSizeArea') {
        return row.sameSizeAreas.length > 0;
      } else if (field === 'isSelected') {
        return row[field] ? 1 : 0;
      }
  
      if (row[field]) {
        if (typeof row[field] === 'string') {
          return row[field].toLowerCase();
        }
        return row[field];
      }
  
      return 0; // Default return for undefined/null fields
    };
  
    const handleLTDS = row => {
      // Handle ltds sorting separately
      return row.ltds ?? 0; // Default to 0 if ltds is undefined/null
    };
  
    let sortedRows;
    if (field === 'finalBuckets') {
      sortedRows = _.orderBy(
        rows,
        [handleField, handleLTDS], // Primary sort by finalBuckets presence, secondary by ltds
        [direction, direction === 'asc' ? 'asc' : 'desc'] // Reverse order for ltds
      );
    } else {
      sortedRows = _.orderBy(rows, handleField, direction);
    }
  
    return reorderData(sortedRows, selectedLot);
  };

  conditionalRowStyles = () => {
    return [
      {            
        when: row => this.props.selectedLot && !isEmpty(this.props.selectedLot) &&
        (
          row.propertyId.toString() === this.props.selectedLot?.propertyId.toString() ||
          row.propertyId.toString() === this.props.hoverMarker?.toString()
        ),
        style: {
          backgroundColor: '#21ba45',
          color: 'white',
        },
      }
    ]
  }

  handleSelectableRowSelected = (row) => {
    return row.isSelected 
  }

  handleSelectableRowsDisabled = (row) => {
    return row.isDisabled
  }

  render() {
    const {data, loading, handleAddressClicked, onHandleSelectedRowsChange, bucket, type} = this.props
    const isPluto = data && data.length > 0 && Lot.isPluto(data[0])
    return (
      <Datatable
      className='comps-table'
      noHeader={true}
      //persistTableHead
      columns={(type === CompTypeOption.RENT ? columnsRentalsV3(handleAddressClicked, bucket, type) : isPluto ? columnsV3(handleAddressClicked, bucket, type) : faColumns(handleAddressClicked, bucket, type)).filter((obj) => Object.keys(obj).length > 0)}
      data={data ? data.filter(_ => _.display) : []}
      defaultSortField='finalBuckets'
      defaultSortAsc={false}
      fixedHeader
      progressPending={loading}
      progressComponent={<Spinner active={loading} inline={true} />}
      conditionalRowStyles={this.conditionalRowStyles()}
      sortFunction={this.customSort}
      customStyles={customStyles}
      //selectableRows
      //selectableRowsComponent={Checkbox}
      //onSelectedRowsChange={onHandleSelectedRowsChange}
      //selectableRowSelected={this.handleSelectableRowSelected}
      pagination
      paginationPerPage={50}
      paginationRowsPerPageOptions={[25,50,100,200]}
      paginationComponentOptions={paginationComponentOptions}
      // subHeaderAlign='left'
      // subHeader
      // subHeaderComponent={(
      //   <Segment className='no-padding' basic style={{width: '100%'}}>
      //     <Button size='mini' floated='left' type='button' content='Export' onClick={(e) => {downloadCSV(data, 'extended-comps', csvColumnsV3)}}/>
      //   </Segment>
      // )}
      />
    )
}
}

export class ProspectingTable extends Component {
  
  customSort = (rows, field, direction) => {
    const {selectedLot} = this.props
    const handleField = row => {
      if (row[field]) {
        if (typeof row[field] === 'string') {
           return row[field].toLowerCase();
        }
      }
  
      return row[field];
    };
  
    return _.orderBy(rows, handleField, direction) // reorderData(_.orderBy(rows, handleField, direction), selectedLot)
  }

  conditionalRowStyles = () => {
    return [
      {            
        when: row => row.propertyId.toString() === this.props.selectedLot?.propertyId.toString()
        || row.propertyId.toString() === this.props.hoverMarker?.toString(),
        style: {
          backgroundColor: '#21ba45',
          color: 'white',
        },
      }
    ]
  }

  render() {
    const {data, loading, handleAddressClicked} = this.props
    const type = data && data.length > 0 ? data[0].type : 'pluto'
    
    return (
      <Datatable
      className='prospecting-table'
      noHeader={true}
      //persistTableHead
      columns={prospectingColumns(type,handleAddressClicked)}
      data={data ? data : []}
      defaultSortField='address'
      fixedHeader
      progressPending={loading}
      progressComponent={<Spinner active={loading} inline={true} />}
      conditionalRowStyles={this.conditionalRowStyles()}
      sortFunction={this.customSort}
      customStyles={customStyles}
      pagination
      paginationPerPage={50}
      paginationRowsPerPageOptions={[25,50,100,200]}
      paginationComponentOptions={paginationComponentOptions}
      />
    )
}
}

export class LenderSummaryTable extends Component {
  
  customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        if (typeof row[field] === 'string') {
           return row[field].toLowerCase();
        }
      }
  
      return row[field];
    };
  
    return _.orderBy(rows, handleField, direction)
  }

  render() {
    const {data, loading, handleLenderClicked} = this.props
    const list = data?.summary || []
    const summaryId = data?.summaryId
    const type = data?.type
    return (
      <Datatable
      className='prospecting-table'
      noHeader={true}
      //persistTableHead
      columns={lenderSummaryColumns(type, summaryId, handleLenderClicked)}
      data={list ? list : []}
      defaultSortField='lenderName'
      fixedHeader
      progressPending={loading}
      progressComponent={<Spinner active={loading} inline={true} />}
      sortFunction={this.customSort}
      customStyles={customStyles}
      pagination
      paginationPerPage={50}
      paginationRowsPerPageOptions={[25,50,100,200]}
      paginationComponentOptions={paginationComponentOptions}
      />
    )
}
}

export class LenderTransactionsTable extends Component {
  customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        if (typeof row[field] === 'string') {
           return row[field].toLowerCase();
        }
      }
  
      return row[field];
    };
  
    return _.orderBy(rows, handleField, direction)
  }

  conditionalRowStyles = () => {
    return [
      {            
        when: row => row.propertyId.toString() === this.props.selectedLot?.propertyId.toString()
        || row.propertyId.toString() === this.props.hoverMarker?.toString(),
        style: {
          backgroundColor: '#21ba45',
          color: 'white',
        },
      }
    ]
  }

  render() {
    const {data, loading, handleAddressClicked, handleBorrowerNameClicked, handleBorrowerAddressClicked} = this.props
    const type = data && data.length > 0 ? data[0].type : 'pluto'
    
    return (
      <Datatable
      className='prospecting-table'
      noHeader={true}
      //persistTableHead
      columns={lenderTransactionsColumns(type, handleAddressClicked, handleBorrowerNameClicked, handleBorrowerAddressClicked)}
      data={data ? data : []}
      defaultSortField='address'
      fixedHeader
      progressPending={loading}
      progressComponent={<Spinner active={loading} inline={true} />}
      conditionalRowStyles={this.conditionalRowStyles()}
      sortFunction={this.customSort}
      customStyles={customStyles}
      pagination
      paginationPerPage={50}
      paginationRowsPerPageOptions={[25,50,100,200]}
      paginationComponentOptions={paginationComponentOptions}
      />
    )
}
}

export class BorrowerTransactionsTable extends Component {
  customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        if (typeof row[field] === 'string') {
           return row[field].toLowerCase();
        }
      }
  
      return row[field];
    };
  
    return _.orderBy(rows, handleField, direction)
  }

  conditionalRowStyles = () => {
    return [
      {            
        when: row => row.propertyId.toString() === this.props.selectedLot?.propertyId.toString()
        || row.propertyId.toString() === this.props.hoverMarker?.toString(),
        style: {
          backgroundColor: '#21ba45',
          color: 'white',
        },
      }
    ]
  }

  render() {
    const {data, loading, handleAddressClicked} = this.props
    const type = data && data.length > 0 ? data[0].type : 'pluto'
    
    return (
      <Datatable
      className='prospecting-table'
      noHeader={true}
      //persistTableHead
      columns={borrowerTransactionsColumns(type,handleAddressClicked)}
      data={data ? data : []}
      defaultSortField='address'
      fixedHeader
      progressPending={loading}
      progressComponent={<Spinner active={loading} inline={true} />}
      conditionalRowStyles={this.conditionalRowStyles()}
      sortFunction={this.customSort}
      customStyles={customStyles}
      pagination
      paginationPerPage={50}
      paginationRowsPerPageOptions={[25,50,100,200]}
      paginationComponentOptions={paginationComponentOptions}
      />
    )
}
}

export class UniqueBorrowerTable extends Component {
  
  customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        if (typeof row[field] === 'string') {
           return row[field].toLowerCase();
        }
      }
  
      return row[field];
    };
  
    return _.orderBy(rows, handleField, direction)
  }

  render() {
    const {data, loading, handleBorrowerNameClicked} = this.props
    
    return (
      <Datatable
      className='prospecting-table'
      noHeader={true}
      //persistTableHead
      columns={uniqueBorrowerColumns(handleBorrowerNameClicked)}
      data={data ? data : []}
      defaultSortField='borrowerName'
      fixedHeader
      progressPending={loading}
      progressComponent={<Spinner active={loading} inline={true} />}
      sortFunction={this.customSort}
      customStyles={customStyles}
      pagination
      paginationPerPage={50}
      paginationRowsPerPageOptions={[25,50,100,200]}
      paginationComponentOptions={paginationComponentOptions}
      />
    )
}
}
